import * as React from 'react';
import "./EntityHeader.css"

type Props = {
    logo: JSX.Element,
    name: string | JSX.Element,
    nameTitle: string,
    leftSide?: JSX.Element,
    rigthSide?: JSX.Element,
    buttons?: JSX.Element,
    views?: JSX.Element,
    entity?: {
        isEditable?: boolean,
        canConfigure?: boolean,
        canCollaborate?: boolean,
        isArchived?: boolean
    }
}
const EntityHeader = (props: Props) => <div className={`entity-header ${props.entity?.isArchived ? "archived" : ""} ${!!props.views ? "with-views" : ""}`}>
    <div className="summary-info align-center">
        {props.logo}
        <div className="name overflow-text" title={props.nameTitle}>
            {props.name}
        </div>
        {props.entity && !props.entity.isEditable && <span className="entity-indicator">
            {!props.entity?.canCollaborate ? "(Read only)" : "(Collaborate)"}
        </span>}
        {props.leftSide}
        <div className="right-side align-center">
            {props.rigthSide}
            <div className="connect-buttons align-center">
                {props.buttons}
            </div>
        </div>
    </div>
    {props.views && <div className="views">
        {props.views}
    </div>}
</div>;

export default EntityHeader;