import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import React from "react";
import { Field, getOptions } from "../../entities/Metadata";
import { IProcessStage, ApprovalType } from "../../entities/Subentities";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { IInputProps } from "../common/interfaces/IInputProps";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { PPMFeatures, Subscription } from "../../store/Tenant";
import DropdownInput, { ColorItemOption } from "../common/inputs/DropdownInput";

type OwnProps = {
    inputProps: IInputProps;
    state: IProcessStage;
    field: Field;
};

type StateProps = {
    subscription: Subscription;
};

type Props = OwnProps & StateProps;

const ApprovalForTransitions = ({ inputProps, state, field, subscription }: Props) => {
    const [showApiApprovalConfirmation, setShowApiApprovalConfirmation] = React.useState<boolean>(false);
    const [fieldValue, setFieldValue] = React.useState<ApprovalType | undefined>();
    
    const options = getColorOptionsWithApiCheck(field, subscription);

    return <>
        {showApiApprovalConfirmation && (
            <ConfirmationDialog
                onDismiss={() => setShowApiApprovalConfirmation(false)}
                onNo={() => { }}
                onYes={() => inputProps.onChanged?.(fieldValue)}
                yesButtonProps={{ text: "Confirm" }}
                noButtonProps={{ text: "Cancel" }}
                dialogContentProps={{
                    title: 'Confirmation',
                    subText: `Are you sure you want to turn API-based Approval on for transitioning from this stage to the next stage?`
                }} >
                    <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                        An external approval process will need to be created, this process will be triggered via API event when user tries to go to the next stage. Transition will only happen once external approval process is completed, and an API event is sent back to PPM Express confirming transition.
                    </MessageBar>
            </ConfirmationDialog>
        )}
    
        <DropdownInput
            {...inputProps}
            inputProps={{ options, readOnly: field.isReadonly }}
            value={state.attributes.ApprovalForTransition} 
            onChanged={(value: any) => {
                if (value === ApprovalType.APIBased) {
                    setShowApiApprovalConfirmation(true);
                    setFieldValue(value);
                } else {
                    inputProps.onChanged?.(value);
                }
            }} 
        />
    </>
}

const apiUpgradeMessage = 'Upgrade your subscription to use API.';

function getColorOptionsWithApiCheck(approvalField: Field, subscription: Subscription): ColorItemOption[] {
    const apiOptionState = getApiBasedOptionState(subscription);
    const options: ColorItemOption[] = getOptions(approvalField);
    const apiBasedOptionInd = options.findIndex(_ => _.key === ApprovalType.APIBased)!;
    options[apiBasedOptionInd] = { ...options[apiBasedOptionInd], ...apiOptionState };
    return options;
}

export function getApiBasedOptionState(subscription: Subscription): { disabled: boolean, title?: string } {
    const hasApiFeature = Subscription.contains(subscription, PPMFeatures.API);
    return {
        disabled: !hasApiFeature,
        title: hasApiFeature ? undefined : apiUpgradeMessage
    };
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
    };
}

export default connect(mapStateToProps)(ApprovalForTransitions);