import React, { useState } from 'react';
import * as Metadata from '../../../entities/Metadata';
import { IContextualMenuItem } from 'office-ui-fabric-react';
import { IFieldActions } from '../../field/FieldPanel';
import { default as ListMenuItemSelectorBuilder } from '../ListMenuItemSelector';
import { EntityType, IBaseEntity } from '../../../entities/common';
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import ContextualMenuToggle from '../ContextualMenuToggle';
import { Styling } from '../timeline/TimelineList';
import SelectionModeSwitchableCommandBar, { SelectionModeSettings } from '../SelectionModeSwitchableCommandBar';
import { ConfigureFieldsPanel } from '../../field/ConfigureFields';

export interface IWithColumns {
    type: 'Columns';
    displayFields: string[];
    canViewConfigureColumns?: boolean;
    flattenAvailableFieldGroups?: boolean;
    onDisplayFieldsChange?: (fields: string[]) => void;
}
export interface IWithViews extends IWithViewsActions {
    type: 'Views';
    activeView?: Metadata.IListSubView;
    views: Metadata.IListSubView[];
}
export interface IWithViewsActions {
    onActiveViewChanged: (id: string) => void;
    onAddSubViewClick: () => void;
    onEditSubViewClick: (id: string) => void;
    onCopySubViewClick: (view: Metadata.IListSubView) => void;
    onRemoveSubViewClick: (id: string) => void;
}
export interface IWithFiltersActions {
    onFilterClick: (id: string) => void;
    onClearFilter: (filters?: Metadata.IFilter<Metadata.BaseFilterValue>[]) => void;
    onAddFilterClick?: () => void;
    onEditFilterClick?: (id: string) => void;
    onCopyFilterClick?: (filter: Metadata.IFilter<Metadata.BaseFilterValue>) => void;
    onRemoveFilterClick?: (id: string) => void;
}
export interface IWithFilters extends IWithFiltersActions {
    useFilters: true;
    disableFilters?: boolean;
    filtersTitle?: string;
    preFilter?: Metadata.PreFilter<IBaseEntity>;
    filters: Metadata.IFilter<Metadata.BaseFilterValue>[];
    activeFilter?: Metadata.IFilter<Metadata.BaseFilterValue>;
}
export interface IOutline {
    expandTitle: string;
    collapseTitle: string;
    expandAll: () => void;
    collapseAll: () => void;
    disabled?: () => boolean;
    outlineTitle?: () => (string | undefined);
}
type StateProps = {
    user: UserState;
    isFieldsLoading: boolean;
};

export type OwnProps = {
    commands: IContextualMenuItem[];
    farCommands?: IContextualMenuItem[];
    configCommands?: IContextualMenuItem[];
    selectionMode?: SelectionModeSettings;
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    entityType: EntityType;
    styling?: Styling; 
    outline?: IOutline;
    mandatoryViewFields?: string[];
} & (({ fields: Metadata.Field[]; } & (IWithColumns | IWithViews)) | { type?: '' })
    & (IWithFilters | { useFilters?: boolean });

type Props = StateProps & OwnProps;

const SubViewListMenuItemSelector = ListMenuItemSelectorBuilder<Metadata.ISubView>();
const FilterListMenuItemSelector = ListMenuItemSelectorBuilder<Metadata.IFilter<Metadata.BaseFilterValue>>();

const ListMenu = (props: Props) => {
    const { user } = props;

    const [isConfiguring, setIsConfiguring] = useState(false);

    const canManageConfiguration = contains(user.permissions.common, CommonOperations.ConfigurationManage);
    const canViewConfiguration = contains(user.permissions.common, CommonOperations.ConfigurationView);

    return <div className="list-menu">
        <SelectionModeSwitchableCommandBar
            items={props.commands}
            farItems={getFarCommandItems()}
            selectionMode={props.selectionMode}
        />
        {props.type === 'Columns' && isConfiguring && <ConfigureFieldsPanel
            onDismiss={() => setIsConfiguring(false)}
            fields={props.fields}
            selected={props.displayFields}
            entityType={props.entityType}
            onChange={props.onDisplayFieldsChange ? onChangedDisplayField : undefined}
            allowManageFields={props.allowManageFields}
            flattenAvailableFieldGroups={props.flattenAvailableFieldGroups}
            fieldActions={props.fieldActions}
            showSpinner={props.isFieldsLoading}
            mandatoryFields={props.mandatoryViewFields}
        />}
    </div>;

    function onChangedDisplayField(displayFields: string[]): void {
        const propsWithColumns = (props as IWithColumns);
        propsWithColumns.onDisplayFieldsChange!(displayFields);
    }

    function getFarCommandItems(): IContextualMenuItem[] {
        const result: IContextualMenuItem[] = [];
        const configCommands = props.configCommands ? [...props.configCommands] : [];

        if (props.outline) {
            const disabled = props.outline?.disabled?.();
            result.push({
                key: 'outline',
                text: 'Outline',
                iconProps: { iconName: 'BulletedTreeList' },
                disabled: disabled,
                title: props.outline?.outlineTitle?.(),
                subMenuProps: {
                    items: [{
                        key: 'expand',
                        text: 'Expand',
                        title: props.outline.expandTitle,
                        iconProps: { iconName: 'PPMXExpandAll' },
                        onClick: props.outline.expandAll
                    },
                    {
                        key: 'collapse',
                        text: 'Collapse',
                        title: props.outline.collapseTitle,
                        iconProps: { iconName: 'PPMXCollapseAll' },
                        onClick: props.outline.collapseAll
                    }]
                }
            });
        }
        if (props.styling) {
            result.push({
                key: 'style',
                text: 'Style',
                iconProps: { iconName: 'Color' },
                subMenuProps: {
                    items: Object.keys(props.styling.settings).map(_ => ({
                        key: _,
                        onRender: (item) => {
                            const settings = props.styling!.settings[_];
                            return (
                                <ContextualMenuToggle
                                    label={props.styling!.settings[_].label}
                                    checked={props.styling!.values[_]}
                                    onChange={(e, checked: boolean) => props.styling!.onChange(_, checked)}
                                    configureTitle={settings.configureTitle}
                                    disableConfigure={settings.disableConfigure?.()}
                                    onConfigure={settings.onConfigure}
                                />
                            );
                        },
                    }))
                }
            });
        }

        if (props.type === 'Views') {
            result.push({
                key: 'views',
                onRender: () => <SubViewListMenuItemSelector
                    itemType='View'
                    activeItem={props.activeView}
                    items={props.views}
                    canManageConfiguration={canManageConfiguration}
                    onActiveItemChanged={props.onActiveViewChanged}
                    onItemAddClick={props.onAddSubViewClick}
                    onItemEditClick={props.onEditSubViewClick}
                    onItemCopyClick={props.onCopySubViewClick}
                    onItemRemoveClick={props.onRemoveSubViewClick}
                />
            });
        }
        else if (props.type === 'Columns') {
            configCommands.push({
                key: 'columns',
                name: 'Configure Columns',
                iconProps: { iconName: 'TripleColumnEdit' },
                disabled: !canViewConfiguration && !props.canViewConfigureColumns,
                onClick: () => setIsConfiguring(true)
            });
        }

        if (props.useFilters) {
            const withFilterProps = props as IWithFilters;
            result.push({
                key: 'filters',
                onRender: () => <FilterListMenuItemSelector
                    iconProps={{ iconName: "Filter" }}
                    disabled={withFilterProps.disableFilters}
                    title={withFilterProps.filtersTitle}
                    itemType='Filter'
                    preItems={withFilterProps.preFilter}
                    useSeparators={true}
                    entityLabel='filters'
                    activeItem={withFilterProps.activeFilter}
                    items={withFilterProps.filters}
                    canManageConfiguration={canManageConfiguration}
                    onActiveItemChanged={withFilterProps.onFilterClick}
                    onItemAddClick={withFilterProps.onAddFilterClick}
                    onItemEditClick={withFilterProps.onEditFilterClick}
                    onItemCopyClick={withFilterProps.onCopyFilterClick}
                    onItemRemoveClick={withFilterProps.onRemoveFilterClick}
                />
            });
        }

        if (configCommands.length) {
            result.unshift(!result.length && configCommands.length > 1
                ? {
                    key: 'more',
                    iconProps: { iconName: 'More' },
                    submenuIconProps: {
                        hidden: true, color: 'red', style: { color: 'blue' }
                    },
                    subMenuProps: { items: configCommands }
                } : configCommands[0]
            );
        }
        
        return [...props.farCommands ?? [], ...result];
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const fields = state.fields[ownProps.entityType];
    return {
        user: state.user,
        isFieldsLoading: fields?.isLoading ?? false,
    };
}

export default connect(mapStateToProps)(ListMenu);