import * as React from 'react';
import { IHeader } from "../../common/ItemCreation";
import { getLabel } from "../../../entities/Metadata";
import { LayoutsState, actionCreators } from "../../../store/layouts";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { EntityType, entityTypeLabelMap } from '../../../entities/common';
import { bindActionCreators } from 'redux';
import { LayoutService } from '../../utils/LayoutService';
import LabellableComponent from '../LabellableComponent';
import * as analytics from '../../../analytics';
import { UserState } from '../../../store/User';
import ListEntityWithNameCreation from '../ListEntityWithNameCreation';
import EntityLayoutsDropdown from './EntityLayoutsDropdown';
import Collapsible from '../Collapsible';

type OwnProps = {
    entityType: EntityType;
    entityTypeLabel?: string;
    onDismiss: () => void;
    onSave: (name: string, layoutId: string) => void;
    hideSectionSelector?: boolean;
    hideTitle?: boolean;
    header?: Partial<IHeader>;
    disabled?: boolean;
    defaultLayoutId?: string;
};

type StateProps = {
    user: UserState;
    layouts: LayoutsState;
};

type IProps = ReturnType<typeof actionCreators.forEntity> & StateProps & OwnProps;

const EntityCreation = (props: React.PropsWithChildren<IProps>) => {
    const [selectedLayoutId, setSelectedLayoutId] = React.useState(LayoutService.getDefault(props.layouts).id);

    React.useEffect(() => {
        const layoutId = props.defaultLayoutId && props.layouts.allIds.includes(props.defaultLayoutId)
            ? props.defaultLayoutId
            : LayoutService.getDefault(props.layouts).id;
        setSelectedLayoutId(layoutId);
    }, [props.defaultLayoutId, props.layouts])

    const entityTypeLabel = props.entityTypeLabel ?? entityTypeLabelMap[props.entityType].singular;

    const onChangeLayout = React.useCallback((layoutId: string | undefined, updateIsDirty: (isDirty: boolean) => void) => {
        if (!layoutId) {
            return;
        }
        updateIsDirty(true);
        setSelectedLayoutId(layoutId);
    }, [setSelectedLayoutId]);

    const onSave = React.useCallback((name: string) => {
        if (name) {
            props.onSave(name, selectedLayoutId);
            analytics.trackCreate(props.user, { itemType: props.entityType, itemTitle: name });
        }
    }, [props.onSave, props.onDismiss, props.user, selectedLayoutId]);

    return (
        <ListEntityWithNameCreation
            entityType={props.entityType}
            header={props.header}
            disabled={props.disabled}
            onSave={onSave}
            onDismiss={props.onDismiss}
        >
            {(updateIsDirty) => (
                <div className="panel-area">
                    <Collapsible key='advanced-options' title='Advanced options' iconName='PPMXGear'>
                        {props.children instanceof Function
                            ? props.children(updateIsDirty)
                            : props.children}
                        {!props.hideSectionSelector && <>
                            <div className="grid-item">
                                {!props.hideTitle && <span>Select a layout that will be applied to this {entityTypeLabel}</span>}
                                <LabellableComponent label='Layout' className="field-container">
                                    <EntityLayoutsDropdown
                                        entityType={props.entityType}
                                        layoutId={selectedLayoutId}
                                        onChanged={(_) => onChangeLayout(_?.id, updateIsDirty)}
                                    />
                                </LabellableComponent>
                            </div>
                            <div className="grid-item">
                                <LabellableComponent label='Layout Sections' className="field-container">
                                    <ul className='simple-list'>
                                        {
                                            props.layouts.byId[selectedLayoutId].sections
                                                .filter(_ => _.isSelected)
                                                .map(_ => <li className='item' key={_.id}>{getLabel(_)}</li>)
                                        }
                                    </ul>
                                </LabellableComponent>
                            </div>
                        </>}
                    </Collapsible>
                </div>
            )}
        </ListEntityWithNameCreation>
    );
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        user: state.user,
        layouts: state.layouts[ownProps.entityType]
    };
}

export default connect(mapStateToProps, (dispatch, ownProps) => bindActionCreators(actionCreators.forEntity(ownProps.entityType), dispatch))(EntityCreation);