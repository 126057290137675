import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { EntityType, PhaseCategory, entityTypeLabelMap, phaseCategoryMap } from '../../../entities/common';
import { FieldActionTypes, ISettingsEditComponent } from '../FieldPanel';
import ColorCategorySettingsEdit, { areCategoryOptionsUnique, areCategoryOptionsValid, isEqual } from './ColorCategorySettingsEdit/ColorCategorySettingsEdit';
import { DraggableOption } from '../SelectSettingsEdit/OptionEdit';
import { buildOptionsUpdate, buildOptions } from '../SelectSettingsEdit/SelectSettingsEdit';
import { FieldType } from '../../../entities/Metadata';
import { useDidMountEffect } from '../../utils/effects';
import { CategoryStageOption, IStageDropDownSettings } from '../../../entities/StageDescriptor';

const orderedCategories = [
    PhaseCategory.Initiating,
    PhaseCategory.Planning,
    PhaseCategory.Executing,
    PhaseCategory.MonitoringAndControlling,
    PhaseCategory.Closing,
    PhaseCategory.Suspended,
    PhaseCategory.Canceled
];

type Props = {
    settings: IStageDropDownSettings;
    entityType: EntityType;
    actionType: FieldActionTypes;
    onChange: (isValid: boolean) => void;
};

const StageSelectSettingsEdit = forwardRef<ISettingsEditComponent, Props>((props, ref) => {
    const { settings, entityType } = props;

    const [draggableOptions, setDraggableOptions] = useState<DraggableOption<CategoryStageOption>[]>(
        settings.options.map((_, index) => ({ ..._, id: 'id_' + index, key: _.name })));

    const isValid = areCategoryOptionsValid(draggableOptions);
    useDidMountEffect(() => props.onChange(isValid), [isValid, draggableOptions]);

    useImperativeHandle(ref, (): ISettingsEditComponent => ({
        save: (fieldInfo) => {
            fieldInfo.type = FieldType.Text;
            fieldInfo.settings = {
                ...fieldInfo.settings,
                editControl: "ColorStageDropdown",
                options: buildOptions(draggableOptions, buildCategoryStageOption)
            };
        },
        update: (fieldInfo) => ({
            ...fieldInfo,
            optionsUpdate: buildOptionsUpdate(settings.options, draggableOptions, buildCategoryStageOption, isEqual)
        })
    }));

    const onCategoryOptionsChange = (options: DraggableOption<CategoryStageOption>[]) => {
        setDraggableOptions(options)
    }

    return <>
        <LearnMoreMessage />
        <ColorCategorySettingsEdit
            categoryConfig={phaseCategoryMap}
            categoryTypeName="Stage"
            defaultInCategoryRequired={false}
            getDeletionWarning={(option: CategoryStageOption) => <DeletionWarningMessage entityType={entityType} option={option} />}
            orderedCategories={orderedCategories}
            options={draggableOptions}
            isUnique={() => areCategoryOptionsUnique(draggableOptions)}
            onChange={(options: DraggableOption<CategoryStageOption>[]) => onCategoryOptionsChange(options)}
        />
    </>;
})

export default StageSelectSettingsEdit;

const LearnMoreMessage = () => <div>
    Define stages for each category. You can drag and drop them to reorder.
</div>;

const DeletionWarningMessage = ({ entityType }: { entityType: EntityType, option: CategoryStageOption }) => <>
    {entityTypeLabelMap[entityType].plural} will no longer be able to transition to this stage.<br />
    Note, if you delete an option which is currently used, the field value will be set empty.
</>;

const buildCategoryStageOption = (draggableOption: DraggableOption<CategoryStageOption>): CategoryStageOption => ({
    category: draggableOption.category,
    name: draggableOption.name,
    color: draggableOption.color
});
