import * as React from "react";
import {
    DetailsControlSettings, Dictionary, EMPTY_GUID, FieldSettings, ISortState, ISortingProps, IStyleSettingValues, ITimeframe, IWithActiveSubViewId, IWithScale,
    IWithSortBy, IWithSubViews, Quantization, TimelineControlSettings, TimelineMapControlSettings, UserPreferencesSettingsUpdate
} from "../../entities/common";
import * as Metadata from '../../entities/Metadata';
import { nameof, toLowerCaseFirstLetter } from "./metadataService";
import { useDebounce } from "../../components/utils/effects";
import { debounceDelay } from "../../components/utils/common";
import * as FiltersStore from '../filters';
import { TimelineChange } from "../../components/common/timeline/TimelineList";

export enum Views {
    List = "Details",
    Timeline = "Timeline",
    TimelineMap = 'TimelineMap',
    FlowChart = 'FlowChart'
}

export type ControlSettings = {
    timelineMap?: TimelineMapControlSettings;
    timeline?: TimelineControlSettings;
    details?: DetailsControlSettings;
    viewType: Views;
}

export enum UpdateType {
    Quantization = 'Quantization',
    Timeframe = 'Timeframe',
    ActiveFilter = 'ActiveFilter',
    Sorting = 'Sorting',
    ColumnResize = 'ColumnResize',
    ActiveSubView = 'ActiveSubView',
    Style = 'Style'
}

export type UserViewPreferencesUpdate<TType = UpdateType> = UserPreferencesSettingsUpdate & {
    type: TType;
};

type OnColumnResize = (fieldId: string, width: number, subViewId?: string) => void;
export type OnSaveSettings<TType = UpdateType> = (update: UserViewPreferencesUpdate<TType>, sendToServer?: boolean) => void;

export type UserSettings = {
    controlSettings: ControlSettings;
    onSaveSettings?: OnSaveSettings
};

export type ListUserSettings = UserSettings & {
    defaultSort?: ISortState;
}

export type TimelineSaverProps = {
    columns?: Metadata.ISubViewColumn[];
    onColumnResize?: OnColumnResize;
    sorting?: ISortingProps;
}

export const useTimelineMapSaver = (props: ListUserSettings): TimelineSaverProps => {
    const userSettings = props.controlSettings.timelineMap;
    const onSaveSettings = useViewOnSaveSettings(Views.TimelineMap, props.onSaveSettings);

    return {
        ...useColumnResize(userSettings, onSaveSettings),
        ...useScale(userSettings, onSaveSettings),
        sorting: useSorting(userSettings, onSaveSettings, props.defaultSort)
    }
}

export const useTimelineSaver = (props: ListUserSettings): TimelineSaverProps => {
    const userSettings = props.controlSettings.timeline;
    const onSaveSettings = useViewOnSaveSettings(Views.Timeline, props.onSaveSettings);

    return {
        ...useColumnResize(userSettings, onSaveSettings),
        ...useScale(userSettings, onSaveSettings),
        sorting: useSorting(userSettings, onSaveSettings, props.defaultSort)
    }
}

export type ListSaverProps = {
    columns?: Metadata.ISubViewColumn[];
    onColumnResize?: OnColumnResize;
    sorting?: ISortingProps;
}

export interface IWithActiveFilter {
    activeFilter?: FiltersStore.ActiveFilter;
}

export const useListSaver = (props: ListUserSettings, sorting?: ISortingProps): ListSaverProps => {
    const userSettings = props.controlSettings.details;
    const onSaveSettings = useViewOnSaveSettings(Views.List, props.onSaveSettings);

    return {
        ...useColumnResize(userSettings, onSaveSettings),
        sorting: {...useSorting(userSettings, onSaveSettings, props.defaultSort), ...sorting}
    }
}

export const useViewOnSaveSettings = (viewType: Views, onSaveSettings?: OnSaveSettings) => React.useCallback<OnSaveSettings>(
    (update, send) => onSaveSettings?.(PreferenceUpdates.viewType(viewType, update), send),
    [onSaveSettings, viewType])

export type IUserSettings<T> = {
    userSettings?: T;
    onSaveSettings?: OnSaveSettings
};

export const useScale = (userSettings?: IWithScale, onSaveSettings?: OnSaveSettings, onScaleChanged?: (timelineChange: TimelineChange) => void) => {
    const onQuantizationChange = React.useCallback(
        (quantization?: Quantization) => onSaveSettings?.(PreferenceUpdates.qunatization(quantization)),
        [onSaveSettings]);
    const onTimeframeChange = React.useCallback(
        (timeframe?: Partial<ITimeframe>) => onSaveSettings?.(PreferenceUpdates.timeframe(timeframe)),
        [onSaveSettings]
    );

    const onScaleChange = React.useCallback(
        (timelineChange: TimelineChange) => {
            if (!timelineChange.origin) {
                return;
            }

            onQuantizationChange(timelineChange.origin.quantization);
            onTimeframeChange(timelineChange.origin.timeframe);
            onScaleChanged?.(timelineChange);
        }, [onSaveSettings]);

    return {
        onScaleChange,
        userTimeframe: getTimeframe(userSettings?.timeframe),
        userQuantization: userSettings?.quantization
    }
}

export const useColumnResize = (userSettings?: IWithSubViews, onSaveSettings?: OnSaveSettings) => {
    const subViewSettings = userSettings?.subViews?.[EMPTY_GUID];
    const columns = React.useMemo(
        () => getColumns(subViewSettings),
        [subViewSettings]);

    const onColumnResize = useDebounce<OnColumnResize>(
        (f, w, s) => onSaveSettings?.(PreferenceUpdates.columnSize(f, w, s)),
        debounceDelay);
    return {
        columns: columns,
        onColumnResize: onColumnResize
    }
}

export const useSorting = (userSettings?: IWithSortBy, onSaveSettings?: OnSaveSettings, defaultSort?: ISortState) => {
    const onSortChange = React.useCallback(
        (sort?: ISortState) => onSaveSettings?.(PreferenceUpdates.sorting(sort)),
        [onSaveSettings]);

    const sortBy = userSettings?.sortBy?.fieldName
        ? userSettings?.sortBy
        : undefined;
    return React.useMemo(() => ({
        orderBy: sortBy ?? defaultSort,
        onChange: onSortChange
    }), [onSortChange, userSettings?.sortBy]);
}

export const PreferenceUpdates = {
    sorting: (sort?: ISortState) => ({
        type: UpdateType.Sorting,
        parentSettingsPathKeys: [
            nameof<DetailsControlSettings>("sortBy")
        ],
        valueBySettingNameMap: {
            [nameof<ISortState>("fieldName")]: sort?.fieldName,
            [nameof<ISortState>("direction")]: sort?.direction
        }
    }),
    columnSize: (fieldId: string, width: number, subViewId?: string) => ({
        type: UpdateType.ColumnResize,
        parentSettingsPathKeys: [
            nameof<DetailsControlSettings>("subViews"),
            subViewId || EMPTY_GUID,
            fieldId
        ],
        valueBySettingNameMap: {
            [nameof<Metadata.ISubViewColumn>("width")]: width
        }
    }),
    timeframe: (timeframe?: Partial<ITimeframe>) => ({
        type: UpdateType.Timeframe,
        parentSettingsPathKeys: [
            nameof<TimelineControlSettings>("timeframe")
        ],
        valueBySettingNameMap: {
            [nameof<ITimeframe>("start")]: timeframe?.start ?? null,
            [nameof<ITimeframe>("end")]: timeframe?.end ?? null
        }
    }),
    qunatization: (quantization?: Quantization) => ({
        type: UpdateType.Quantization,
        parentSettingsPathKeys: [],
        valueBySettingNameMap: {
            [nameof<TimelineControlSettings>("quantization")]: quantization ?? null
        }
    }),
    viewType: (viewType: Views, update: UserViewPreferencesUpdate) => ({
        type: update.type,
        parentSettingsPathKeys: [
            ...([toLowerCaseFirstLetter(viewType)]),
            ...(update.parentSettingsPathKeys ?? [])
        ],
        valueBySettingNameMap: update.valueBySettingNameMap ?? {}
    }),
    filter: ({ filterId, preFilterId }: { filterId?: string | null, preFilterId?: string | null }) => ({
        type: UpdateType.ActiveFilter,
        parentSettingsPathKeys: [
            nameof<IWithActiveFilter>("activeFilter")
        ],
        valueBySettingNameMap: {
            ...(filterId !== undefined ? { [nameof<FiltersStore.ActiveFilter>("filterId")]: filterId } : undefined),
            ...(preFilterId !== undefined ? { [nameof<FiltersStore.ActiveFilter>("preFilterId")]: preFilterId } : undefined)
        }
    }),
    activeSubViewId: (activeSubViewId: string) => ({
        type: UpdateType.ActiveSubView,
        parentSettingsPathKeys: [],
        valueBySettingNameMap: {
            [nameof<IWithActiveSubViewId>("activeSubViewId")]: activeSubViewId
        }
    }),
    style: (key: string, value: boolean) => ({
        type: UpdateType.Style,
        parentSettingsPathKeys: [
            nameof<IWithStyleSettings>("styleSettingValues")
        ],
        valueBySettingNameMap: {
            [key]: value
        }
    })
}

export const getTimeframe = (propsTimeframe?: Partial<ITimeframe>) => {
    return propsTimeframe?.start || propsTimeframe?.end
        ? parseTimeframeDataToDateType(propsTimeframe)
        : undefined;
}

const parseTimeframeDataToDateType = (timeframe: Partial<ITimeframe>): Partial<ITimeframe> => {
    const timeframeCopy = {};
    writeDate("start", timeframe, timeframeCopy);
    writeDate("end", timeframe, timeframeCopy);
    return timeframeCopy;
}

const writeDate = (props: keyof ITimeframe, source: Partial<ITimeframe>, target: Partial<ITimeframe>) => {
    const value = source[props];
    if (!value) {
        return;
    }

    if (typeof value === 'string') {
        target[props] = new Date(value);
    } else {
        target[props] = source[props];
    }
}

const getColumns = (subViewSettings: Dictionary<FieldSettings> | undefined): Metadata.ISubViewColumn[] =>
    Object.keys(subViewSettings ?? {}).map(_ => ({ id: _, width: subViewSettings?.[_].width }));

export type IWithStyleSettings<TValues = IStyleSettingValues> = { styleSettingValues: TValues }