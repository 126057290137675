import * as Metadata from './MetadataStore';
import * as User from './User';
import * as Financials from './FinancialsStore';
import * as Time from './TimeStore';
import * as Tenant from './Tenant';
import * as Portfolios from './PortfoliosListStore';
import * as Programs from './ProgramsListStore';
import * as ProjectsList from './ProjectsListStore';
import * as ArchivedProjectsList from './ArchivedProjectsListStore';
import * as Resources from './ResourcesListStore';
import * as Challenges from './ChallengesListStore';
import * as StrategicPriorities from './StrategicPrioritiesListStore';
import * as Ideas from './IdeasListStore';
import * as Import from './integration/ImportStore';
import * as Office365 from './integration/Office365Store';
import * as PPMXTime from './integration/PPMXTimeStore';
import * as Jira from './integration/JiraStore';
import * as VSTS from './integration/VSTSStore';
import * as Spo from './integration/SpoStore';
import * as MondayCom from './integration/MondayComStore';
import * as Smartsheet from './integration/SmartsheetStore';
import * as P4W from './integration/P4WStore';
import * as Notifications from "./NotificationsStore";
import * as Users from "./UsersListStore";
import * as Error from "./ErrorStore";
import * as Fields from "./fields";
import * as Filters from "./filters";
import * as Views from "./views";
import * as Sections from './Sections';
import * as WarningsTypeMaps from "./warningsTypeMapsStore";
import * as Layouts from "./layouts";
import * as OdataTokensListStore from "./OdataTokensListStore";
import * as Insights from "./InsightsStore";
import * as InsightsSettings from "./InsightsSettingsStore";
import * as Calendar from "./CalendarStore";
import * as MyWork from "./MyWorkStore";
import { AuthProvider, Dictionary, EntityType } from '../entities/common';
import { Reducer, Action } from 'redux';
import { BaseFilterValue } from '../entities/Metadata';
import { StoreHelper } from './services/storeHelper';
import * as CallengeViews from './challenge/views';
import * as PortfolioViews from './portfolio/views';
import * as ProgramViews from './program/views';
import * as ProjectViews from './project/views';
import * as ResourceViews from './resource/views';
import * as ObjectiveViews from './objective/views';
import * as RoadmapViews from './roadmap/views';
import * as RoadmapItemViews from './roadmapItem/views';
import * as Objectives from './ObjectivesListStore';
import * as Roadmaps from './RoadmapsListStore';
import * as Processes from './ProcessesListStore';
import * as ReportsListStore from './ReportsListStore';
import * as PngExporter from './PngExporterStore';
import * as UserInterface from './UserInterfaceStore';
import * as UtilizationStore from './UtilizationStore';
import * as ExtensionsStore from './ExtensionStore';
import * as SignalRStore from './SignalRStore';
import * as TimeTrackingStore from './TimeTrackingStore';
import * as ReportedTimeStore from './ReportedTimeStore';
import * as PersonalOffice365Store from './integration/PersonalOffice365Store';

import { FeaturesDescription } from '../components/settings/billing/common';

// The top-level state object
export interface ApplicationState {
    metadata: Metadata.MetadataState;
    user: User.UserState;
    analytics: AnalyticsState;
    financials: Financials.FinancialsState;
    time: Time.TimeState;
    tenant: Tenant.TenantState;
    portfolios: Portfolios.PortfoliosState;
    programs: Programs.ProgramsState;
    projectsList: ProjectsList.ProjectsListState;
    archivedProjectsList: ArchivedProjectsList.ArchivedProjectsListState;
    resources: Resources.ResourcesState;
    challenges: Challenges.ChallengesState;
    strategicPriorities: StrategicPriorities.StrategicPrioritiesState;
    ideas: Ideas.IdeasState;
    import: Import.ImportState;
    office365: Office365.Office365State;
    smartsheet: Smartsheet.SmartsheetState;
    ppmxTime: PPMXTime.PPMXTimeState;
    jira: Jira.JiraState;
    vsts: VSTS.VSTSState;
    spo: Spo.SpoState;
    p4w: P4W.P4WState;
    mondayCom: MondayCom.MondayComState,
    notifications: Notifications.NotificationsState;
    users: Users.UsersState;
    fields: Dictionary<Fields.FieldsState>;
    filters: Dictionary<Filters.IFiltersState<BaseFilterValue>>;
    views: Dictionary<Views.IViewsState>;
    sections: Sections.SectionsState;
    layouts: Dictionary<Layouts.LayoutsState>;
    error: Error.ErrorState;
    odataTokens: OdataTokensListStore.ODataTokensState;
    reports: ReportsListStore.ReportsState;
    warningsTypeMaps: WarningsTypeMaps.WarningsTypeMapState;
    insights: Insights.InsightsState;
    insightsSettings: InsightsSettings.InsightsSettingsState;
    calendar: Calendar.CalendarState;
    myWork: MyWork.MyWorkState;
    chargebee: ChargebeeState;
    objectives: Objectives.ObjectivesState;
    roadmaps: Roadmaps.RoadmapsState;
    processes: Processes.ProcessesState;
    pngExporter: PngExporter.PngExporterState;
    publicApi: PublicAPI;
    enviroment: EnviromentState
    userInterface: UserInterface.UserInterfaceState;
    utilization: UtilizationStore.UtilizationState;
    extensions: ExtensionsStore.ExtensionsState;
    sirnalR: SignalRStore.SignalRState;
    timeTracking: TimeTrackingStore.TimeTrackingState;
    reportedTime: ReportedTimeStore.ReportedTimeState;
    personalOffice365: PersonalOffice365Store.PersonalO365ConnectionState
}

export interface EnviromentState {
    version: string;
    disabledSignUp: boolean;
    authProviders: AuthProvider[];
}

export interface PublicAPI {
    url?: string;
    version?: string;
}

export interface AnalyticsState {
    segmentWriteKey: string;
    calendlyEventPath: string;
    calendlyRequestCall: string;
    intercomAppId: string | null;
    appcues: AppcuesOptions;
}

export interface ChargebeeState {
    plans: SignUpPlan[];
    siteName: string;
}

export interface SignUpPlan {
    key: string;
    title: string;
    description?: string;
    iconName?: string;
    features?: FeaturesDescription[];
    isDefault: boolean;
    isDefaultFromTeams: boolean;
    productType: Tenant.ProductType;
    isPopular: boolean;
}

export interface AppcuesOptions {
    commonScenarios: { productType: string; scenarioId: string }[];
    perPageScenarios: { path: string; productType: string; scenarioId: string }[];
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    metadata: Metadata.reducer,
    user: User.reducer,
    analytics: (state: { segmentWriteKey: string, calendlyEventPath: string, calendlyRequestCall: string, intercomAppId: string | null } = {
        segmentWriteKey: '',
        calendlyEventPath: '',
        calendlyRequestCall: '',
        intercomAppId: null
    }, action: { type: any }) => { return state; },
    financials: Financials.reducer,
    time: Time.reducer,
    tenant: Tenant.reducer,
    portfolios: Portfolios.reducer,
    programs: Programs.reducer,
    projectsList: ProjectsList.reducer,
    archivedProjectsList: ArchivedProjectsList.reducer,
    resources: Resources.reducer,
    challenges: Challenges.reducer,
    ideas: Ideas.reducer,
    strategicPriorities: StrategicPriorities.reducer,
    import: Import.reducer,
    office365: Office365.reducer,
    ppmxTime: PPMXTime.reducer,
    jira: Jira.reducer,
    vsts: VSTS.reducer,
    spo: Spo.reducer,
    mondayCom: MondayCom.reducer,
    smartsheet: Smartsheet.reducer,
    p4w: P4W.reducer,
    notifications: Notifications.reducer,
    users: Users.reducer,
    fields: perEntity(Fields.reducer),
    filters: perEntity(Filters.reducer),
    views: perEntity(Views.reducer),
    sections: Sections.reducer,
    warningsTypeMaps: WarningsTypeMaps.reducer,
    layouts: perEntity(Layouts.reducer),
    error: Error.reducer,
    odataTokens: OdataTokensListStore.reducer,
    reports: ReportsListStore.reducer,
    insights: Insights.reducer,
    insightsSettings: InsightsSettings.reducer,
    calendar: Calendar.reducer,
    chargebee: (state: { plans: SignUpPlan[] } = { plans: [] }, action: { type: any }) => { return state; },
    myWork: MyWork.reducer,
    objectives: Objectives.reducer,
    roadmaps: Roadmaps.reducer,
    processes: Processes.reducer,
    pngExporter: PngExporter.reducer,
    publicApi: (state: PublicAPI = {}, action: { type: any }) => state,
    enviroment: (state: EnviromentState = { version: '', disabledSignUp: false, authProviders: [] }, action: { type: any }) => state,
    userInterface: UserInterface.reducer,
    utilization: UtilizationStore.reducer,
    extensions: ExtensionsStore.reducer,
    sirnalR: SignalRStore.reducer,
    timeTracking: TimeTrackingStore.reducer,
    reportedTime: ReportedTimeStore.reducer,
    personalOffice365: PersonalOffice365Store.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}

function perEntity<S>(r: Reducer<S>): Reducer<Dictionary<S>> {
    interface NamespacedAction<T = any> extends Action<T> {
        entity: string
    }

    return (state: Dictionary<S>, incomingAction: Action) => {
        const action = incomingAction as NamespacedAction;
        const newState: Dictionary<S> = { ...state };

        if (action.entity) {
            newState[action.entity] = r(state[action.entity], incomingAction);
        }
        return newState;
    };
}
const toObject = <U, T>(cum: U, cur: { _: string, store: T }) => ({ ...cum, [cur._]: cur.store });

export const rootReducer: Reducer<ApplicationState> = (state: ApplicationState, incomingAction: Metadata.MetadataLoadedAction) => {
    if (incomingAction.type === "METADATA_LOADED") {
        const viewsDefaults: Dictionary<Views.IViewsState> = {
            [EntityType.Challenge]: CallengeViews.VIEWS_DEFAULTS,
            [EntityType.Project]: ProjectViews.getViewsDefaults(state.user),
            [EntityType.Resource]: ResourceViews.VIEWS_DEFAULTS,
            [EntityType.Portfolio]: PortfolioViews.getViewsDefaults(state.user),
            [EntityType.Program]: ProgramViews.getViewsDefaults(state.user),
            [EntityType.Objective]: ObjectiveViews.VIEWS_DEFAULTS,
            [EntityType.Roadmap]: RoadmapViews.VIEWS_DEFAULTS,
            [EntityType.RoadmapItem]: RoadmapItemViews.VIEWS_DEFAULTS
        };
        const newState: Partial<ApplicationState> = {
            fields: Object.keys(incomingAction.data.fields)
                .map<{ _: string; store: Fields.FieldsState }>(_ => ({ _, store: { isLoading: false, ...StoreHelper.create(incomingAction.data.fields[_]) } }))
                .reduce(toObject, {}),
            filters: Object.keys(incomingAction.data.filters)
                .map<{ _: string; store: Filters.IFiltersState<BaseFilterValue> }>(_ => {
                    const entityFilters = incomingAction.data.filters[_];
                    const store = StoreHelper.create(entityFilters.filters);
                    return {
                        _,
                        store: {
                            ...store,
                            active: { [Filters.FilterKeys.Main]: entityFilters.active ?? {} }
                        }
                    };
                })
                .reduce(toObject, {}),
            views: Object.keys(incomingAction.data.views)
                .map<{ _: string; store: Views.IViewsState }>(_ => {
                    const state = viewsDefaults[_] ?? Views.VIEWS_EMPTY;
                    const entityViews = incomingAction.data.views[_];
                    return {
                        _,
                        store: {
                            ...state,
                            activeViewType: entityViews.activeType ?? state.activeViewType,
                            card: state.card ? Views.reduceCardState(state.card, entityViews.views) : state.card,
                            timeline: state.timeline ? Views.reduceTimelineState(state.timeline, entityViews.views) : state.timeline,
                            list: Views.reduceListState(state.list, entityViews.views)
                        }
                    };
                })
                .reduce(toObject, {}),
            layouts: Object.keys(incomingAction.data.layouts)
                .map<{ _: string; store: Layouts.LayoutsState }>(_ => ({ _, store: StoreHelper.create(incomingAction.data.layouts[_]) }))
                .reduce(toObject, {}),
            extensions: {
                entities: incomingAction.data.extensions,
                isLoading: false,
                isProcessing: false,
                error: null
            }
        }
        return { ...state, ...newState };
    }
    return state;
}