import React from 'react';
import { IRoadmapItem, IRoadmapItemAttrs } from "../../entities/Subentities";
import { nameof } from "../../store/services/metadataService";
import { Field, FormatType, Group } from '../../entities/Metadata';
import { DisplayFieldService } from "../common/DisplayFieldService";
import GroupDropdown from "../common/inputs/GroupDropdown";
import { IValidator, Validator } from '../../validation';
import DatePickerInput from '../common/inputs/DatePickerInput';
import { isMilestone } from './map';
import { FormatDate, formatValue, toDate } from '../utils/common';
import { StatusCategory, Dictionary, EntityType, mapServerEntityType } from '../../entities/common';
import { ActionButton } from 'office-ui-fabric-react';
import NumberInput from "../common/inputs/NumberInput";
import { IInputProps } from "../common/interfaces/IInputProps";
import * as StatusDescriptorFactory from "../../entities/StatusDescriptorFactory";
import { FieldsState } from '../../store/fields';
import StatusDescriptor from '../../entities/StatusDescriptor';

type OriginalValueProps = {
    attrName: keyof IRoadmapItemAttrs;
    state: IRoadmapItem;
    converter: (value: unknown, origin?: boolean) => string | undefined;
    compareValues?: (roadmapItemValue: unknown, originValue: unknown) => boolean;
    onClick?: (value: unknown) => void;
};

const OriginalValue = (props: OriginalValueProps): JSX.Element | null => {
    const { attrName, state, converter, compareValues, onClick } = props;
    const originIsDeleted = state.externalData?.OriginIsDeleted;
    const originValue = state.externalData?.OriginAttributes?.[attrName];
    const originStr = converter(originValue, true)?.toString();
    const roadmapItemValue = state.attributes[attrName];

    const valuesEqual = (): boolean => !compareValues ? originStr === converter(roadmapItemValue, false) : compareValues(roadmapItemValue, originValue);

    if (originIsDeleted || originValue === undefined || valuesEqual()) {
        return null;
    }

    return <div className="origin-box">
        <span className="overflow-text origin-text">Source: {originStr}</span>
        {onClick && (
            <ActionButton
                className="apply-btn"
                title="The current value from source item will be applied"
                onClick={() => onClick(originValue)}
            >
                Apply
            </ActionButton>
        )}
    </div>;
}

export const rendersBuilder = (groups: Group[], labels: Group[], fieldsStateMap: Dictionary<FieldsState>) => ({
    [nameof<IRoadmapItemAttrs>("StartDate")]: (props: IInputProps, state: IRoadmapItem, field: Field, validator?: IValidator): JSX.Element | null =>
        <>
            <div>
                <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={validator}
                    maxDate={isMilestone(state) ? undefined : state.attributes.FinishDate} />
            </div>
            <OriginalValue
                attrName="StartDate"
                state={state}
                converter={_ => FormatDate(_ as string, { year: 'numeric', month: 'numeric', day: 'numeric' })}
                onClick={props.onChanged && !props.disabled && !field.isReadonly ? (originValue: string) => {
                    const { value, extra } = verifyDates(state.attributes, "StartDate", originValue);
                    props.onChanged!(value, extra);
                } : undefined} />
        </>,
    [nameof<IRoadmapItemAttrs>("FinishDate")]: (props: IInputProps, state: IRoadmapItem, field: Field, validator?: IValidator): JSX.Element | null =>
        <>
            <div>
                <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={validator}
                    minDate={isMilestone(state) ? undefined : state.attributes.StartDate} />
            </div>
            <OriginalValue
                attrName="FinishDate"
                state={state}
                converter={_ => FormatDate(_ as string, { year: 'numeric', month: 'numeric', day: 'numeric' })}
                onClick={props.onChanged && !props.disabled && !field.isReadonly
                    ? (originValue: string) => {
                        const { value, extra } = verifyDates(state.attributes, "FinishDate", originValue);
                        props.onChanged!(value, extra);
                    }
                    : undefined}
                />
        </>,
    [nameof<IRoadmapItemAttrs>("Lane")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <GroupDropdown dropdownInputProps={props} value={props.value} groups={groups} showEmptyRow={!field.settings?.required} />,
    [nameof<IRoadmapItemAttrs>("Label")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <GroupDropdown dropdownInputProps={props} value={props.value} groups={labels} showEmptyRow={!field.settings?.required} />,
    [nameof<IRoadmapItemAttrs>("ICEScore")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcICEScore(state)} />,
    [nameof<IRoadmapItemAttrs>("RICEScore")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcRICEScore(state)} />,
    [nameof<IRoadmapItemAttrs>("ValueVsEffortScore")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcValueVsEffortScore(state)} />,
    [nameof<IRoadmapItemAttrs>("CostOfDelay")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcCostOfDelay(state)} />,
    [nameof<IRoadmapItemAttrs>("WSJF")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcWSJF(state)} />,
    [nameof<IRoadmapItemAttrs>("Progress")]: (props: IInputProps, state: IRoadmapItem, field: Field, validator?: IValidator): JSX.Element | null =>
        <>
            <div>
                {DisplayFieldService.buildFieldInputElement(field, props, EntityType.RoadmapItem, undefined, undefined, undefined, validator)}
            </div>
            <OriginalValue
                attrName="Progress"
                state={state}
                converter={(_: number) => formatValue(_, FormatType.Percent)}
                onClick={props.onChanged && !props.disabled && !field.isReadonly
                    ? (originValue: number) => props.onChanged!(originValue)
                    : undefined}
            />
        </>,
    [nameof<IRoadmapItemAttrs>("Status")]: (props: IInputProps, state: IRoadmapItem, field: Field, validator?: IValidator): JSX.Element | null =>
        <>
            <div>
                {DisplayFieldService.buildFieldInputElement(field, props, EntityType.RoadmapItem)}
            </div>
            <OriginalValue
                attrName="Status"
                state={state}
                converter={(statusVal: string | undefined, origin) => convertStatus(statusVal, origin, field, state, fieldsStateMap)}
                compareValues={(roadmapItemStatusVal: string | undefined, originStatusVal: string | undefined) =>
                    compareStatuses(roadmapItemStatusVal, originStatusVal, field, state, fieldsStateMap)}
                onClick={getStatusOriginalValueOnClick(props, state, field, fieldsStateMap)}
            />
        </>
});

export const rendersInlineBuilder = (groups: Group[], labels: Group[], fieldsStateMap: Dictionary<FieldsState>) => ({
    [nameof<IRoadmapItemAttrs>("StartDate")]: (props: IInputProps, state: IRoadmapItem, field: Field, validator?: IValidator): JSX.Element | null =>
        isMilestone(state)
            ? null
            : <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={validator}
                maxDate={state.attributes.FinishDate} />,
    [nameof<IRoadmapItemAttrs>("FinishDate")]: (props: IInputProps, state: IRoadmapItem, field: Field, validator?: IValidator): JSX.Element | null =>
        <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={validator}
            minDate={isMilestone(state) ? undefined : state.attributes.StartDate} />,

    [nameof<IRoadmapItemAttrs>("Lane")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <GroupDropdown  value={props.value} groups={groups} showEmptyRow={!field.settings?.required} hideCaretDown
            dropdownInputProps={{ ...props, onEditComplete: () => props.onEditComplete(null) }} />,
    [nameof<IRoadmapItemAttrs>("Label")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <GroupDropdown value={props.value} groups={labels} showEmptyRow={!field.settings?.required} hideCaretDown
            dropdownInputProps={{ ...props, onEditComplete: () => props.onEditComplete(null) }} />,

    [nameof<IRoadmapItemAttrs>("ICEScore")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcICEScore(state)} />,
    [nameof<IRoadmapItemAttrs>("RICEScore")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcRICEScore(state)} />,
    [nameof<IRoadmapItemAttrs>("ValueVsEffortScore")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcValueVsEffortScore(state)} />,
    [nameof<IRoadmapItemAttrs>("CostOfDelay")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcCostOfDelay(state)} />,
    [nameof<IRoadmapItemAttrs>("WSJF")]: (props: IInputProps, state: IRoadmapItem, field: Field): JSX.Element | null =>
        <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} value={Prioritization.calcWSJF(state)} />,
});

export const validatorBuilder: Dictionary<(state: IRoadmapItem, field: Field) => IValidator> = {
    [nameof<IRoadmapItemAttrs>("StartDate")]: (state: IRoadmapItem, field: Field): IValidator => {
        let validator = Validator.new();
        if (!isMilestone(state)) {
            validator = validator.required().dateIsLessThenOrEqual(state.attributes.FinishDate);
        }
        return validator.build();
    },
    [nameof<IRoadmapItemAttrs>("FinishDate")]: (state: IRoadmapItem, field: Field): IValidator => {
        let validator = Validator.new().required();
        if (!isMilestone(state)) {
            validator = validator.dateIsGreaterThenOrEqual(state.attributes.StartDate);
        }
        return validator.build();
    }
}

export function matchRoadmapItemAndOriginStatuses(roadmapItemStatusField: Field, roadmapItem: IRoadmapItem, fieldsStateMap: Dictionary<FieldsState>): boolean {
    const roadmapItemStatusDescriptor = StatusDescriptorFactory.createStatusDescriptor(roadmapItemStatusField);
    const originStatus = roadmapItem.externalData?.OriginAttributes?.Status;
    const roadmapItemStatusOptionWithOriginName = roadmapItemStatusDescriptor.getOption(originStatus);

    return compareStatuses(roadmapItemStatusOptionWithOriginName?.name, originStatus, roadmapItemStatusField, roadmapItem, fieldsStateMap);
}

function compareStatuses(
    roadmapItemStatus: string | undefined,
    originStatus: string | undefined,
    roadmapItemStatusField: Field,
    state: IRoadmapItem,
    fieldsStateMap: Dictionary<FieldsState>
): boolean {
    if (roadmapItemStatus === undefined || originStatus === undefined || roadmapItemStatus !== originStatus) {
        return false;
    }
    
    const roadmapItemStatusDescriptor = StatusDescriptorFactory.createStatusDescriptor(roadmapItemStatusField);
    const originStatusDescriptor = createOriginStatusDescriptor(state, fieldsStateMap)!;

    const roadmapItemStatusOption = roadmapItemStatusDescriptor.getOption(roadmapItemStatus);
    const originStatusOption = originStatusDescriptor.getOption(originStatus);

    return originStatusOption !== undefined
        && roadmapItemStatusOption !== undefined
        && originStatusOption.category === roadmapItemStatusOption.category;
}

const verifyDates = (attributes: IRoadmapItemAttrs, attrName: keyof IRoadmapItemAttrs, originValue: unknown) => {
    const extraAttributeUpdates: Partial<IRoadmapItemAttrs> = {};
    if (attrName === nameof<IRoadmapItemAttrs>("StartDate")) {
        const originStartDateStr = originValue as string
        const originStartDate = toDate(originStartDateStr);
        const finishDate = toDate(attributes.FinishDate);
        if (!!originStartDate && !!finishDate && originStartDate > finishDate) {
            extraAttributeUpdates.FinishDate = originStartDateStr;
        }
    }
    if (attrName === nameof<IRoadmapItemAttrs>("FinishDate")) {
        const startDate = toDate(attributes.StartDate);
        const originFinishDateStr = originValue as string
        const originFinishDate = toDate(originFinishDateStr);
        if (!!originFinishDate && !!startDate && originFinishDate < startDate) {
            extraAttributeUpdates.StartDate = originFinishDateStr;
        }
    }
    return { value: originValue, extra: extraAttributeUpdates }
}

function convertStatus(
    status: string | undefined,
    origin: boolean | undefined,
    roadmapItemStatusField: Field,
    state: IRoadmapItem,
    fieldsStateMap: Dictionary<FieldsState>
): string | undefined {
    if (status) {
        return status;
    }

    const currStatusDescriptor = origin
        ? createOriginStatusDescriptor(state, fieldsStateMap)
        : StatusDescriptorFactory.createStatusDescriptor(roadmapItemStatusField);

    return currStatusDescriptor?.getCategoryDefaultStatusValue(StatusCategory.NA);
}

function getStatusOriginalValueOnClick(props: IInputProps, roadmapItem: IRoadmapItem, roadmapItemStatusField: Field, fieldsStateMap: Dictionary<FieldsState>) {
    const hasMatchedStatus = matchRoadmapItemAndOriginStatuses(roadmapItemStatusField, roadmapItem, fieldsStateMap);
    if (props.onChanged && !props.disabled && !roadmapItemStatusField.isReadonly && hasMatchedStatus) {
        return (originValue: string) => props.onChanged!(originValue);
    }
    
    return undefined;
}

function createOriginStatusDescriptor(state: IRoadmapItem, fieldsStateMap: Dictionary<FieldsState>): StatusDescriptor | undefined {
    const importedFromType = state.externalData["ImportedFromType"];
    if (!importedFromType) {
        return undefined;
    }
    const originEntityType = mapServerEntityType[importedFromType]!;
    const originFields = fieldsStateMap[originEntityType].allIds.map(_ => fieldsStateMap[originEntityType].byId[_]);
    return StatusDescriptorFactory.createStatusDescriptorFor(originEntityType, originFields);
}

// this functionality realized on server side too (in RoadmapItemService.ISubEntityCustomUpdater<RoadmapItem>)
class Prioritization {
    public static calcICEScore(item: IRoadmapItem): number | undefined {
        return item.attributes.Impact && item.attributes.Confidence && item.attributes.Ease
            ? item.attributes.Impact * item.attributes.Confidence * item.attributes.Ease
            : undefined;
    }

    public static calcRICEScore(item: IRoadmapItem): number | undefined {
        return item.attributes.Reach && item.attributes.Impact && item.attributes.Confidence && item.attributes.Effort
            ? this.roundScore(item.attributes.Reach * item.attributes.Impact * item.attributes.Confidence / item.attributes.Effort)
            : undefined;
    }

    public static calcValueVsEffortScore(item: IRoadmapItem): number | undefined {
        return item.attributes.Impact && item.attributes.Effort
            ? this.roundScore(item.attributes.Impact / item.attributes.Effort)
            : undefined;
    }

    public static calcCostOfDelay(item: IRoadmapItem): number | undefined {
        return item.attributes.UserBusinessValue && item.attributes.TimeCriticality && item.attributes.RROE
            ? item.attributes.UserBusinessValue + item.attributes.TimeCriticality + item.attributes.RROE
            : undefined;
    }

    public static calcWSJF(item: IRoadmapItem): number | undefined {
        const costOfDelay = this.calcCostOfDelay(item);
        return costOfDelay && item.attributes.JobSize
            ? this.roundScore(costOfDelay / item.attributes.JobSize)
            : undefined;
    }

    private static roundScore(score?: number): number | undefined {
        return score !== undefined ? Math.round(score * 100) / 100 : score;
    }
}
