import { IProcessAttrs } from '../../../store/process/common';
import { Section } from '../../../entities/Metadata';
import * as FieldsArea from '../../common/sectionsControl/uiControls/fieldsArea/common';
import { namesof } from '../../../store/services/metadataService';
import { ISubentitiesListSettings } from '../../common/SubentitiesList';
import { IProcessStageAttrs } from '../../../entities/Subentities';
import { Views } from '../../../store/services/viewSaver';

export const DETAILS_SECTION_ID = '7b5a8059-ec4a-4c53-b45d-a8cd5680e3a6';

export const ProcessSections: Section[] = [
    {
        id: DETAILS_SECTION_ID,
        name: 'Details',
        isSelected: true,
        isOpen: true,
        isAccessible: true,
        settings: { iconName: "PPMXSectionDetails" },
        uiControls: [
            {
                id: 'e7e292-cb4f-474c-8e5c-ccdee1038b95',
                type: 'FieldsArea',
                settings: {
                    fields: {
                        column1: namesof<IProcessAttrs>(['Name', 'EntityType', 'Status', 'Description']),
                        column2: []
                    }
                } as FieldsArea.ISettings
            }
        ],
    },
    {
        id: '113e1ef6-7a64-4948-88dd-08627e705dfe',
        name: 'Stages',
        isSelected: true,
        isOpen: true,
        isAccessible: true,
        settings: { iconName: "CRMProcesses", viewType: Views.List },
        uiControls: [
            {
                id: '5fd70be0-3732-420e-a24b-f0d1f23bbede',
                type: 'ProcessStagesControl',
                settings: {
                    displayFields: namesof<IProcessStageAttrs>(['Name', 'Order', 'ApprovalForTransition', 'EntityLayout', 'Description']),
                } as ISubentitiesListSettings
            }
        ]
    }
];
