import * as React from 'react';
import { Dropdown, IDropdownOption, IDropdown, Icon } from 'office-ui-fabric-react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import { IFormInputComponent } from "../interfaces/IFormInputComponent";

export type DropdownInputProps = IFormInputProps<string | number | (string | number)[]> & {
    multichoice?: boolean,
    useEmptyValue?: boolean,
    hideCaretDown?: boolean
}

export default class DropdownInput extends React.Component<DropdownInputProps> implements IFormInputComponent {
    private _dropdown = React.createRef<IDropdown>();

    componentDidMount() {
        this.props.inputRef && this.props.inputRef(this);
    }

    private isDropdown(node: Element): any {
        const list: NodeListOf<Element> = document.querySelectorAll(".ms-Layer .ms-Callout.ms-Dropdown-callout");
        for (let i = 0; i < list.length; i++) {
            if (list[i].contains(node)) {
                return true;
            }
        }
        return false;
    }

    public render(): JSX.Element {
        const { inputProps, value, disabled, readOnly, multichoice, useEmptyValue, hideCaretDown, className } = this.props;
        const isReadOnly = readOnly || inputProps?.readOnly;
        const isDisabled = disabled || inputProps?.disabled;

        const options: ColorItemOption[] = (!multichoice && useEmptyValue
            ? Array.of<ColorItemOption>({ key: placeholderKey, text: "" }) : [])
            .concat(inputProps ? inputProps.options : []);

        // nail: some fields (e.g. Project.Progress) have number value and string based options list
        // but Dropdown require type match for options and value. 
        // That`s why we use abstract(type–converting) comparison to find option and use it key
        const selectedKeys = options.filter(_ =>
            multichoice
                ? value && Array.isArray(value) ? ~(value as any[]).findIndex(__ => _.key == __) : false
                : _.key === (value ?? placeholderKey))
            .map(_ => _.key) as string[] | number[];

        const dropDownProps = {
            ...inputProps
        };

        const useColored = options.some(_ => _.color);

        if (useColored) {
            dropDownProps.onRenderOption = ColorOption;
            dropDownProps.onRenderTitle = ColorTitle;
        }

        //need for VSTS import because onBlur event finished cell editing
        return <div className={`ms-Dropdown-wrapper ${className ?? ""}`}
            onClick={e => e.stopPropagation()}
            onBlur={e => this.isDropdown(e.relatedTarget as Element) && e.stopPropagation()}>
            <Dropdown
                onRenderCaretDown={hideCaretDown ? () => null : undefined}
                {...dropDownProps}
                options={options}
                disabled={isDisabled}
                onKeyDown={e => { if (isDisabled || isReadOnly) { e.preventDefault(); } }}
                componentRef={this._dropdown}
                multiSelect={multichoice}
                selectedKey={multichoice ? undefined : (selectedKeys.length ? selectedKeys : [placeholderKey])}
                selectedKeys={multichoice ? selectedKeys : undefined}
                onChange={isDisabled || isReadOnly ? undefined : this._onChange}
            />
        </div>
    }

    private _onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        const { value, multichoice } = this.props;

        var newValue: string | number | (string | number)[] | null = null;
        if (multichoice) {
            newValue = (value ? [...value as any] : []) as (string | number)[];
            if (item.selected) {
                newValue = [...newValue, item.key] as (string | number)[];
            }
            else {
                const currIndex = newValue.indexOf(item.key);
                if (~currIndex) {
                    newValue.splice(currIndex, 1);
                }
            }
        } else {
            newValue = item.key === placeholderKey ? null : item.key;
        }

        this.props.onChanged?.(newValue);
        this.props.onEditComplete?.(newValue);
    }

    focus(): void {
        this._dropdown.current!.focus(true);
    }
}

const placeholderKey = 'placeholder';

export type ColorItemOption = IDropdownOption & { color?: string };

export const TextOption = (item?: IDropdownOption) => item
    ? <div className='overflow-text'>{item.text}</div>
    : null;
export const ColorOption = (item?: ColorItemOption, index?: number) => item
    ? item.key !== placeholderKey
        ? <div key={`${item.key}_${index}`} className='dropdown-input-option'>
            <Icon className='dropdown-input-option-icon' style={{ backgroundColor: item.color }} iconName={item.color ? undefined : "PPMXColorNone"} />
            <span className='overflow-text'>{item.text}</span>
        </div>
        : <TextOption {...item} />
    : null;
export const ColorTitle = (items?: ColorItemOption[]) => <div className='dropdown-input-title'>
    {items
        ?.map((item, index) => ColorOption(item, index))
        .reduce((prev, curr, index) => [prev, <span key={index}>, </span>, curr] as any)}
</div>