
import React from "react";
import { Dictionary } from "../../../../entities/common";
import { Icon } from "office-ui-fabric-react";

type Props = {
    item: FlowChartItem;
    getChartItemStyles: GetChartItemStyles;
}

type FlowChartItemStyles = {
    style: React.CSSProperties;
    iconName: string;
}

export const ItemCard = React.forwardRef<HTMLDivElement, Props>(({ item, getChartItemStyles }, ref) => {
    const itemInfo = getChartItemStyles(item);
    return <div
        ref={ref}
        className={`flow-chart-item-card`}
        style={itemInfo.style}
        title={item.attributes.Name}>
        <Icon iconName={itemInfo.iconName} />
        <span className="overflow-text">{item.attributes.Name}</span>
    </div>;
})

export type FlowChartItem = { id: string, attributes: { Name?: string } & Dictionary<any> }
export type GetChartItemStyles = (item: FlowChartItem) => FlowChartItemStyles;