import * as React from 'react';
import { IconButton } from 'office-ui-fabric-react';
import { ViewType } from '../resource/usage/ResourceUsageGrid';
import { mergeDeep } from '../../store/utils';
import { IControlConfiguration, UIControlHeaderProps } from './interfaces/ISectionUIControlProps';
import { RouteComponentProps } from 'react-router-dom';
import { EntityType } from '../../entities/common';
import { withRouter } from "react-router-dom";
import { useUrlHelper } from '../../entities/Subentities';
import { useEffectTillTrue } from '../utils/effects';
import { Views } from '../../store/services/viewSaver';

export type ViewTypeSelectView<T> = {
    type: T;
    title: string;
    iconName: string;
}

type Props<T> = {
    views: ViewTypeSelectView<T>[];
    onItemSelected: (type: T) => void;
    selected: T;
};

export const ViewTypeSelect = <T extends string | number,>({ views, onItemSelected, selected }: Props<T>) => <>{
    views.map(view => {
        const isSelected = selected === view.type;
        return <IconButton
            key={view.type}
            iconProps={{ iconName: view.iconName }}
            title={view.title}
            className={`icon-switcher ${(isSelected) ? 'on' : ''}`}
            onClick={e => {
                e.stopPropagation();
                !isSelected && onItemSelected(view.type);
            }} />
    })
}</>;

export const ViewsTypes = {
    DetailList: { type: Views.List, title: 'List View', iconName: 'PPMXListView' },
    DetailCard: { type: Views.List, title: 'Card View', iconName: 'PPMXCardView' },
    Timeline: { type: Views.Timeline, title: 'Timeline View', iconName: 'PPMXTimelineView' },
    TimelineHistory: { type: Views.Timeline, title: 'History View', iconName: 'History' },
    ResourceUsage: [
        { type: ViewType.Hours, title: 'Hours View', iconName: 'HourGlass' },
        { type: ViewType.Percent, title: 'Percent View', iconName: 'CalculatorPercentage' },
        { type: ViewType.FTE, title: 'FTE View', iconName: 'UserGauge' },
        { type: ViewType.Chart, title: 'Chart View', iconName: 'Diagnostic' }
    ]
}

export const ViewTypeViews = {
    StatusesControl: {
        default: ViewsTypes.DetailCard.type,
        views: [ViewsTypes.DetailCard, ViewsTypes.TimelineHistory]
    },
    KeyDatesControl: {
        default: ViewsTypes.Timeline.type,
        views: [ViewsTypes.DetailList, ViewsTypes.Timeline]
    },
    DeliverablesControl: {
        default: ViewsTypes.Timeline.type,
        views: [ViewsTypes.DetailList, ViewsTypes.Timeline]
    },
    ResourcePlanControl: {
        default: ViewType.Hours,
        views: ViewsTypes.ResourceUsage
    },
    Insights: {
        default: ViewsTypes.DetailList.type,
        views: [ViewsTypes.DetailList, ViewsTypes.Timeline]
    },
    Workload: {
        default: ViewsTypes.DetailList.type,
        views: [ViewsTypes.DetailList, ViewsTypes.Timeline]
    },
    SubObjectivesControl: {
        default: ViewsTypes.Timeline.type,
        views: [ViewsTypes.DetailList, ViewsTypes.Timeline]
    },
    ContributingProjectsControl: {
        default: ViewsTypes.Timeline.type,
        views: [ViewsTypes.DetailList, ViewsTypes.Timeline]
    },
    ProgramsControl: {
        default: ViewsTypes.Timeline.type,
        views: [ViewsTypes.DetailList, ViewsTypes.Timeline]
    },
    ProjectsControl: {
        default: ViewsTypes.Timeline.type,
        views: [ViewsTypes.DetailList, ViewsTypes.Timeline]
    },
    UtilizationControl: {
        default: ViewType.Hours,
        views: ViewsTypes.ResourceUsage
    },
    TaskControl: {
        default: ViewsTypes.Timeline.type,
        subentityType: EntityType.Task,
        views: [ViewsTypes.DetailList, ViewsTypes.Timeline]
    },
    RoadmapItemsControl: {
        default: Views.TimelineMap,
        subentityType: EntityType.RoadmapItem,
        views: [
            ViewsTypes.DetailList,
            { type: Views.TimelineMap, title: 'Roadmap View', iconName: 'PPMXTimelineView' }
        ]
    },
    LinkedRoadmapControl: {
        default: Views.TimelineMap,
        subentityType: EntityType.RoadmapItem,
        views: [
            { type: Views.TimelineMap, title: 'Roadmap View', iconName: 'PPMXTimelineView' }
        ]
    },
    ProjectStagesControl: {
        default: Views.FlowChart,
        views: [
            ViewsTypes.DetailList,
            { type: Views.FlowChart, title: 'Flow Chart', iconName: 'CRMProcesses' }
        ]
    },
    ProcessStagesControl: {
        default: Views.List,
        views: [
            ViewsTypes.DetailList,
            { type: Views.FlowChart, title: 'Flow Chart', iconName: 'CRMProcesses' }
        ]
    }
}

type UrlViewTypeSelectProps = {
    selected: Views;
    views: ViewTypeSelectView<Views>[];
    subentityType: EntityType;
    onItemSelected: (viewType: Views) => void;
    isLoaded: boolean;
}

const UrlViewTypeSelect = withRouter<UrlViewTypeSelectProps>((props: UrlViewTypeSelectProps & RouteComponentProps<{}>) => {
    const urlHelper = useUrlHelper(props.history, props.location, props.subentityType);
    const urlViewType = urlHelper.getUrlViewType(Views, props.subentityType);
    useEffectTillTrue(() => {
        if (!props.isLoaded) {
            return false;
        }

        if (urlViewType && urlViewType !== props.selected) {
            props.onItemSelected(urlViewType);
        }

        return true;
    }, [props.isLoaded, urlViewType, props.selected]);

    React.useEffect(() => {
        if (!props.isLoaded) {
            return;
        }

        if (urlViewType !== props.selected) {
            urlHelper.openViewType(props.selected);
        }
    }, [urlHelper.openViewType, urlViewType, props.selected]);

    return <ViewTypeSelect<Views> {...props} />;
});

type ViewTypeSelectProps<T> = { default: T, views: ViewTypeSelectView<T>[] }

export const viewTypeSettingsBuilder = <T extends string | number, TSettings extends { viewType: T }>(settings: TSettings, defaultViewType: T) => {
    const viewTypeSettings = ({ viewType: settings.viewType || defaultViewType }) as Partial<TSettings>;
    return mergeDeep(settings, viewTypeSettings);
}

export const buildViewTypeHeaderRender = <T extends string | number>(props: ViewTypeSelectProps<T>) =>
    <TSettings extends { viewType: T }>({ settings, onChange }: UIControlHeaderProps<TSettings>) => <ViewTypeSelect
        selected={settings.viewType}
        views={props.views}
        onItemSelected={viewType => onChange({ expand: true, update: { viewType } as Partial<TSettings> })}
    />

export const buildViewTypeSelect = <T extends string | number, TSettings extends { viewType: T }>(props: ViewTypeSelectProps<T>): IControlConfiguration<any, TSettings> => ({
    settingsBuilder: settings => viewTypeSettingsBuilder(settings, props.default),
    headerRender: buildViewTypeHeaderRender(props)
})

export const buildUrlViewTypeHeaderRender = (props: ViewTypeSelectProps<Views> & { subentityType: EntityType }) =>
    <TSettings extends { viewType: Views }>({ settings, onChange, isLoaded }: UIControlHeaderProps<TSettings>) => <UrlViewTypeSelect
        selected={settings.viewType}
        views={props.views}
        subentityType={props.subentityType}
        onItemSelected={viewType => onChange({ expand: true, update: { viewType } as Partial<TSettings> })}
        isLoaded={isLoaded}
    />

export const withViewType = <TSettings extends { viewType: Views }>(viewType: Views) =>
    (settings: TSettings) => mergeDeep(settings, ({ viewType }) as Partial<TSettings>);