
import "./FlowChart.css";
import React, { createRef } from "react";
import { groupBy } from "../../../utils/common";
import { GroupCardRef, FlowChartGroup, GroupCard } from "./GroupCard";
import { FlowChartItem, GetChartItemStyles } from "./ItemCard";


type Calculations = {
    arrows: { x1: number, y1: number, x2: number, y2: number }[];
    systemItem?: { top: number, left: number };
}

type SystemItem = { systemAtItemIndex: number, title?: string }

export type FlowChartProps<T extends FlowChartItem = FlowChartItem> = {
    getChartItemStyles: GetChartItemStyles;
    getGroupKey: (item: T) => string | number;
    getGroupInfo: (groupKey: string | number) => FlowChartGroup;
    getSystemItem?: () => SystemItem;
    getActiveItem?: (entities: T[]) => T | undefined;
    filter?: (item: T) => boolean;
    isExpandedDefault?: boolean;
}

export const FlowChart = <T extends FlowChartItem>(props: FlowChartProps<T> & { entities: T[]; }) => {
    const { entities, getGroupKey, getGroupInfo, getChartItemStyles, getSystemItem, getActiveItem, filter, isExpandedDefault } = props;
    const [calculations, setCalculations] = React.useState<Calculations>({ arrows: [] });

    const groups = React.useMemo(
        () => groupBy(filter ? entities.filter(filter) : entities, getGroupKey),
        [entities, filter, getGroupKey]);

    const activeItem = React.useMemo(() => getActiveItem?.(filter ? entities.filter(filter) : entities), [entities, filter]);

    const systemItem = getSystemItem?.() ?? ({ systemAtItemIndex: -1 });

    const viewRef = React.useRef<HTMLDivElement>(null);
    const refs = React.useRef(Array.of<React.RefObject<GroupCardRef>>());

    React.useLayoutEffect(() => {
        if (viewRef.current) {
            setCalculations(calculate(viewRef.current, refs.current, systemItem.systemAtItemIndex));
        }
    }, [entities, refs.current, systemItem.systemAtItemIndex]);

    React.useLayoutEffect(() => {
        if (!viewRef.current) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            if (!viewRef.current) {
                return;
            }

            setCalculations(calculate(viewRef.current, refs.current, systemItem.systemAtItemIndex));
        });

        resizeObserver.observe(viewRef.current);
        return () => resizeObserver.disconnect();
    }, [viewRef.current, refs.current, systemItem.systemAtItemIndex])

    React.useEffect(() => {
        refs.current = Object.keys(groups).map(_ => createRef<GroupCardRef>());
    }, [groups]);

    const isInactive = !!~systemItem.systemAtItemIndex;
    return <div className="flow-chart-view-container">
        <div className='flow-chart-view' ref={viewRef}>
            <div className="flow-chart-list">
                {
                    Object.entries(groups).map(([_, values], index) => {
                        const activeItemInGroup = values.find(v => v.id === activeItem?.id);
                        return <GroupCard
                            key={_}
                            ref={refs.current[index]}
                            className={isInactive ? 'inactive' : activeItemInGroup ? 'is-active' : ''}
                            group={getGroupInfo(_)}
                            items={values}
                            getChartItemStyles={getChartItemStyles}
                            activeItem={activeItemInGroup}
                            isExpandedDefault={isExpandedDefault}
                        />
                    })
                }
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <marker id="arrowhead" markerWidth="6" markerHeight="10" refX="6" refY="5" orient="auto">
                        <polygon points="0 0, 6 5, 0 10" />
                    </marker>
                </defs>
                <g key={1}>
                    {calculations.arrows.map((_, i) => <line key={i} className="relation-arrow" stroke="#BDBDBD" markerEnd="url(#arrowhead)" x1={_.x1} y1={_.y1} x2={_.x2} y2={_.y2} />)}
                </g>
            </svg>
            {!!calculations.systemItem && <div className="system-item-container">
                <div style={{ ...calculations.systemItem }} className="system-item">{systemItem.title}</div>
            </div>}
        </div>
    </div>;
}

function pairwise<T, U>(arr: T[], func: (el1: T, el2: T) => U) {
    const result = Array.of<U>();
    for (let i = 0; i < arr.length - 1; i++) {
        result.push(func(arr[i], arr[i + 1]));
    }

    return result;
}

const calculate = (root: HTMLDivElement, groupsRefs: React.RefObject<GroupCardRef>[], systemAtItemIndex: number): Calculations => {
    const viewRect = root.getBoundingClientRect()!;
    const rects = groupsRefs
        .reduce((a, c) => [...a, ...(c.current?.stagesRefs ?? [])], [])
        .map(_ => _.getBoundingClientRect())
        .map(_ => ({
            left: _.left - viewRect.left,
            top: _.top - viewRect.top,
            width: _.width,
            height: _.height
        }))
    const arrows = pairwise(rects, (a, b) => ({
        x1: a.left + a.width,
        y1: a.top + a.height / 2,
        x2: b.left,
        y2: b.top + b.height / 2
    }));
    const systemAtItem = rects[systemAtItemIndex];
    if (!systemAtItem) {
        return { arrows };
    }

    const point = { x1: systemAtItem.left + systemAtItem.width / 2, y1: systemAtItem.top + systemAtItem.height };
    const arrow = { x1: point.x1, y1: point.y1, x2: point.x1, y2: point.y1 + 32 };
    return {
        arrows: [...arrows, arrow],
        systemItem: { top: arrow.y2, left: positiveOrZero(arrow.x1 - 100) }
    };
}

const positiveOrZero = (number: number) => number < 0 ? 0 : number;