import { IEditable, IWithName, ServerEntityType, PhaseCategory } from "../../entities/common";
import { IProcessStage } from "../../entities/Subentities";

export type StagePhase = {
    id: string;
    category: PhaseCategory;
    name: string;
    color: string;
};

export interface StagePhaseInfo { 
    id: string;
    category: PhaseCategory;
    name: string;
    color: string;
}

export interface Process extends IEditable {
    id: string;
    attributes: IProcessAttrs;
    isDefault: boolean;
    areStagesEditable: boolean;
    isSystem: boolean;
    stagePhases: StagePhase[];
    stages: IProcessStage[];
    entitiesCount: number;
    isLegacy: boolean;
}

export type IProcessAttrs = IWithName & {
    Description: string;
    Status: ProcessStatus;
    EntityType: ServerEntityType;
    CreatedDate: string;
};

export enum ProcessStatus {
    Draft = 0,
    Active = 1,
    Inactive = 2,
}

export const canBeDeleted = (process: Process) => process.isEditable && process.areStagesEditable && !process.isDefault && !process.entitiesCount;
