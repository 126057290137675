import { IFilter, BaseFilterValue, IFilterHelper, FilterAttribute, Field, getLabel, IEntityFilterHelper, IActiveFilter } from "../../entities/Metadata";
import { Dictionary } from "../../entities/common";
import { DisplayFieldService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import { ActiveFilter } from "../project/filters";
import { Process } from "./common";

type FilterHelperProps = {
    fields: Field[];
};

export class FilterHelper implements IEntityFilterHelper<Process> {
    public getFilterAttributes = (fields: Field[]): FilterAttribute<BaseFilterValue>[] => {
        const attrs = fields.map((_) => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) } as FilterAttribute<BaseFilterValue>));
        return attrs;
    };

    private attributes: IFilterHelper<BaseFilterValue, Process> = {
        buildFilterElement: (
            attr: FilterAttribute<BaseFilterValue>,
            filter: IFilter<BaseFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const field: Field = attr.value;
            return DisplayFieldService.buildFieldMultiSelectInput(field, filter.value?.attributes?.[field.name], (changed) => onFilterEditComplete(attr.type, field.name, changed));
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            const newValue: Dictionary<any> = {};
            Object.keys(typeValue).forEach((vk) => {
                if (vk !== attrName) {
                    newValue[vk] = typeValue[vk];
                }
            });
            return newValue;
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            if (!oldValue) {
                oldValue = {};
            }
            const tmpValue = Object.assign({}, oldValue);
            tmpValue[attrName] = value;
            return tmpValue;
        },
        getAttributeValues: (value: any): string[] => FieldsService.getAttributeDisplayValues(this._props.fields, value),
        validateItem: (item: Process, filterValue: any, attributes: any[]): boolean => {
            if (!filterValue) {
                return false;
            }
            for (const key in filterValue) {
                if (filterValue.hasOwnProperty(key)) {
                    if (filterValue[key] === undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                        continue;
                    }

                    const attribute = attributes.find((_) => _.name === key);
                    if (!attribute) {
                        return false;
                    }
                    const field = attribute.value;
                    // todo: use types
                    const value = (item.attributes as any)[key];

                    if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                        return false;
                    }
                }
            }
            return true;
        },
    };

    private readonly _props: FilterHelperProps;
    constructor(props: FilterHelperProps) {
        this._props = props;
    }

    public newFilter = (name: string): IActiveFilter => new ActiveFilter(name);
    public helpersMap: { [K: string]: IFilterHelper<BaseFilterValue, Process> } = {
        attributes: this.attributes,
    };
}
