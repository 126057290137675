import * as React from 'react';
import * as analytics from '../../analytics';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { ApplicationState } from '../../store';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import { IContainerSectionInfo, LayoutableSectionsContainer } from '../common/sectionsControl/SectionsContainer';
import * as Metadata from '../../entities/Metadata';
import {
    Dictionary, EntityType, Impact, UpdateContext, IInsightsData, IWarning, KeyResultAssignment, PpmxConnection, IWithSourceInfos, SectionNames, ISourceInfo
} from "../../entities/common";
import {
    Iteration, LessonLearned, buildActions, IIterationAttrs, IKeyDateAttrs, Dependency, IDependencyAttrs,
    buildKeyDateActions, buildRiskActions, buildIssuesActions, buildIterationActions, buildKeyDecisionActions, buildSteeringCommitteeActions, buildPurchaseOrderActions,
    buildInvoiceActions, buildChangeRequestActions, buildDependencyActions, buildActionItemActions, buildLessonLearnedActions, buildUpdateUIControl, IActionItemAttrs,
    buildDeliverablesActions
} from "../../entities/Subentities";
import { SourceType } from "../../store/ExternalEpmConnectStore";
import * as Notifications from "../../store/NotificationsStore";
import ExternalEpmConnectControl from '../project/ExternalEpmConnectControl';
import { DetailsSpinner } from "../common/Spinner";
import { Validator } from "../../validation";
import ProjectPortfoliosPicker from '../common/inputs/ProjectPortfoliosPicker';
import { ActionButton, IconButton, IContextualMenuItem } from 'office-ui-fabric-react';
import { Reporting, ReportNav } from '../utils/reporting';
import DataSourceSelect, { getDefaultSelected } from '../common/DataSourceSelect';
import * as WarningsTypeMapsStore from "../../store/warningsTypeMapsStore";
import * as LayoutsStore from '../../store/layouts';
import { EntitySectionWarning } from "../common/warnings/EntitySectionWarning";
import { warningService } from "../../store/services/warningService";
import * as StrategicAlignmentControl from '../common/sectionsControl/uiControls/StrategicAlignmentControl';
import * as TimelineControl from '../common/sectionsControl/uiControls/TimelineControl';
import * as RisksControl from '../common/sectionsControl/uiControls/RisksControl';
import * as StatusesControl from '../common/sectionsControl/uiControls/StatusesControl';
import * as KeyDatesControl from '../common/sectionsControl/uiControls/KeyDatesControl';
import * as IterationsControl from '../common/sectionsControl/uiControls/IterationsControl';
import * as ScheduleControl from '../common/sectionsControl/uiControls/ScheduleControl';
import * as ResourcePlanControl from '../common/sectionsControl/uiControls/ResourcePlanControl';
import * as TeamMembersControl from '../common/sectionsControl/uiControls/TeamMembersControl';
import * as TimeControl from '../common/sectionsControl/uiControls/TimeControl';
import * as KeyResultAssignmentsControl from '../common/sectionsControl/uiControls/KeyResultAssignmentsControl';
import * as KPIControl from '../common/sectionsControl/uiControls/KPIControl';
import * as IssuesControl from '../common/sectionsControl/uiControls/IssuesControl';
import { Insights, StatusCalculationTypes, Integrations, Subscription, PPMFeatures } from '../../store/Tenant';
import * as LessonsLearnedControl from '../common/sectionsControl/uiControls/LessonsLearnedControl';
import * as ActionItemsControl from '../common/sectionsControl/uiControls/ActionItemsControl';
import * as KeyDecisionsControl from '../common/sectionsControl/uiControls/KeyDecisionsControl';
import * as SteeringCommitteeControl from '../common/sectionsControl/uiControls/SteeringCommitteeControl';
import * as ProjectStagesControl from '../common/sectionsControl/uiControls/ProjectStagesControl';
import * as PurchaseOrdersControl from '../common/sectionsControl/uiControls/PurchaseOrdersControl';
import * as InvoicesControl from '../common/sectionsControl/uiControls/InvoicesControl';
import * as DeliverablesControl from '../common/sectionsControl/uiControls/DeliverablesControl';
import * as ChangeRequestsControl from '../common/sectionsControl/uiControls/ChangeRequestsControl';
import * as DependenciesControl from '../common/sectionsControl/uiControls/DependenciesControl';
import * as EmbeddedContentControl from '../common/sectionsControl/uiControls/EmbeddedContentControl';
import { nameof } from '../../store/services/metadataService';
import { ViewTypeViews, buildViewTypeHeaderRender, buildViewTypeSelect, viewTypeSettingsBuilder, withViewType } from '../common/ViewTypeSelect';
import * as CalendarStore from '../../store/CalendarStore';
import { UserState, isInReadonlyMode } from '../../store/User';
import AccessRequestConfirmationPanel from '../common/AccessRequestConfirmationPanel';
import EntityRefInput from '../common/inputs/EntityRefInput';
import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import { mergeDeep } from '../../store/utils';
import { IControlConfiguration } from '../common/interfaces/ISectionUIControlProps';
import { Views } from '../../store/services/viewSaver';
import { FieldsState } from '../../store/fields';
import { IInputProps } from '../common/interfaces/IInputProps';
import { NotificationType } from '../../store/NotificationsStore';
import { IFinancialsConfiguration } from '../common/FinancialsContent';
import ImportSubentityFromTask from '../import/project/ImportSubentityFromTask';
import { validators, buildIWithBenefitsValidators, rendersBuilder, buildIWithBenefitsCustomRenders } from '../field/Fields';
import * as LinkedRoadmapControl from '../common/sectionsControl/uiControls/LinkedRoadmapControl';
import { buildTimelineItem, renderIterationSegmentTooltipContent } from './timeline';
import { renderMarkerTooltipContent, renderSegmentContent } from '../portfolio/commonTimeline';
import ProjectHeader from './ProjectHeader';
import { buildReadOnlyStatusFieldsNames, IFieldsAreaConfiguration } from '../common/sectionsControl/uiControls/fieldsArea/common';

export type ActionProps = {
    portfoliosActions: typeof PortfoliosListStore.actionCreators;
    programsActions: typeof ProgramsListStore.actionCreators;
    projectsActions: typeof ProjectsListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    layoutsActions: ReturnType<typeof LayoutsStore.actionCreators.forEntity>;
    warningsTypeMapsActions: typeof WarningsTypeMapsStore.actionCreators;
    calendarActions: typeof CalendarStore.actionCreators;
};
export type StateProps = {
    user: UserState;
    entity?: ProjectsListStore.ProjectInfo;
    warnings?: IWarning[];
    isLoading: boolean;
    isUpdatingSections: boolean;
    layouts: LayoutsStore.LayoutsState;
    projectReports: ReportNav[];
    warningsTypeMap?: WarningsTypeMapsStore.EntityWarningsTypeMap;
    sectionWarningsTypeMap?: Dictionary<string[]>;
    insights: Insights;
    timePortalUrl: string;
    integrations: Integrations;
    isReadonlyMode: boolean;
    hasArchiveProjects: boolean;
    hasPortfolioManagement: boolean;
    hasIdeation: boolean;
    fields: Dictionary<FieldsState>;
    isAiInsightsLoading: boolean;
    aiEnabled: boolean;
    newTimeTrackingEnabled: boolean;
};
type ProjectDetailsProps =
    StateProps
    & ActionProps
    & RouteComponentProps<{ id: string }>;

type ProjectDetailsState = {
    isCofigurationPanelOpen: boolean;
    shareWith?: string;
};
interface IConfiguration extends Dictionary<IControlConfiguration> {
    FieldsArea: IFieldsAreaConfiguration;
    KeyDatesControl: KeyDatesControl.IConfiguration;
    RisksControl: RisksControl.IConfiguration;
    IterationsControl: IterationsControl.IConfiguration;
    IssuesControl: IssuesControl.IConfiguration;
    ResourcePlanControl: ResourcePlanControl.IConfiguration;
    LessonsLearnedControl: LessonsLearnedControl.IConfiguration;
    ActionItemsControl: ActionItemsControl.IConfiguration;
    KeyDecisionsControl: KeyDecisionsControl.IConfiguration;
    SteeringCommitteeControl: SteeringCommitteeControl.IConfiguration;
    PurchaseOrdersControl: PurchaseOrdersControl.IConfiguration;
    InvoicesControl: InvoicesControl.IConfiguration;
    ChangeRequestsControl: ChangeRequestsControl.IConfiguration;
    DependenciesControl: DependenciesControl.IConfiguration;
    BudgetControl: IFinancialsConfiguration;
    BenefitsControl: IFinancialsConfiguration;
    StatusesControl: StatusesControl.IConfiguration;
    TeamMembersControl: TeamMembersControl.IConfiguration;
    ScheduleControl: ScheduleControl.IConfiguration;
    StrategicAlignmentControl: StrategicAlignmentControl.IConfiguration;
    TimeControl: TimeControl.IConfiguration;
    KeyResultAssignmentsControl: KeyResultAssignmentsControl.IConfiguration;
    KPIControl: KPIControl.IConfiguration;
    EmbeddedContentControl: EmbeddedContentControl.IConfiguration;
    DeliverablesControl: DeliverablesControl.IConfiguration;
    TimelineControl: TimelineControl.IConfiguration;
    LinkedRoadmapControl: LinkedRoadmapControl.IConfiguration;
    ProjectStagesControl: ProjectStagesControl.IConfiguration
}

const entityName = EntityType.Project;
export class ProjectDetails extends React.Component<ProjectDetailsProps, ProjectDetailsState> {
    constructor(props: ProjectDetailsProps) {
        super(props);
        this.state = {
            isCofigurationPanelOpen: this.props.location.state ? !!this.props.location.state.configureConnections : false,
            shareWith: new URLSearchParams(this.props.location.search).get('shareWith') ?? undefined
        };
    }
    componentWillMount() {
        this.props.warningsTypeMapsActions.loadWarningsTypeMap(entityName);
        this.props.projectsActions.loadProject(this.props.match.params.id);
        if (this.props.hasPortfolioManagement) {
            this.props.portfoliosActions.requestPortfolios();
            this.props.programsActions.requestPrograms();
        }

        this.props.calendarActions.load();
    }

    componentWillReceiveProps(nextProps: ProjectDetailsProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.props.projectsActions.loadProject(nextProps.match.params.id);
        }
    }

    public render() {
        const { entity, warnings, isLoading, layouts, isUpdatingSections, warningsTypeMap } = this.props;
        const { shareWith } = this.state;

        return <DetailsSpinner isLoading={isLoading}>
            {entity && <ProjectHeader
                entity={entity}
                warnings={warnings}
                warningsTypeMap={warningsTypeMap?.common}
                actions={{
                    openConfigurationPanel: this.openConfigurationPanel,
                    updateImage: this.updateImage,
                    removeImage: this.removeImage,
                    setFavorite: this.setFavorite,
                    layoutActions: {
                        viewLayout: this.viewLayout,
                        applyLayout: this.applyLayout,
                        updateEntityLayout: this.updateDefaultLayout,
                        updateEntityPinnedViews: this.updatePinnedViews,
                        saveLayout: this.saveLayout,
                        deleteLayout: this.deleteLayout
                    },
                    cloneProject: this.cloneProject,
                    removeProject: this.removeProject,
                    archiveProject: this.props.hasArchiveProjects ? this.archiveProject : undefined,
                    updateSettings: this.updateSettings,
                    setProjectBaseline: this.setProjectBaseline
                }}
            />}
            {entity && entity.sections &&
                <LayoutableSectionsContainer
                    entity={entity}
                    entityType={EntityType.Project}
                    warnings={warnings}
                    onHeaderMiddleRender={this._onSectionHeaderMiddlePartRender}
                    reporting={!entity.isArchived ? { reports: this.props.projectReports, onClick: this._onReportClick } : undefined}
                    layouts={layouts}
                    isUpdatingSections={isUpdatingSections}
                    controlsConfig={this._buildControlsConfigurations()}
                />
            }
            {
                entity && this.state.isCofigurationPanelOpen &&
                <ExternalEpmConnectControl
                    entity={entity}
                    onDismiss={() => {
                        this.setState({ isCofigurationPanelOpen: false });
                        this.props.history.replace({ ...this.props.location, state: {} });
                    }}
                />
            }
            {
                entity && shareWith &&
                <AccessRequestConfirmationPanel
                    entity={entity}
                    entityType={EntityType.Project}
                    entityTypeLabel="Project"
                    userId={shareWith}
                    layouts={layouts}
                    onDismiss={() => {
                        this.setState({ shareWith: undefined });
                        const query = new URLSearchParams(this.props.location.search);
                        if (query.has('shareWith')) {
                            query.delete('shareWith');
                            this.props.history.replace({ search: query.toString() });
                        }
                    }}
                />
            }
        </DetailsSpinner>
    }

    private _onReportClick = (report: ReportNav) => {
        Reporting.openProjectReport(this.props.history, report, this.props.entity!);
    }

    private importKeyDates = (data: Dictionary<string[]>) => {
        this.props.projectsActions.linkKeyDate(this.props.entity!.id, data);
    }

    private importDeliverables = (data: Dictionary<string[]>) => {
        this.props.projectsActions.linkDeliverable(this.props.entity!.id, data);
    }

    private buildNewLessonLearned = (): LessonLearned => {
        return {
            attributes: {
                ProjectStage: this.props.entity!.attributes.Stage?.name
            }
        } as LessonLearned;
    }

    private onEditComplete = (fieldName: string, fieldValue: any): void => {
        if (!fieldName) {
            return;
        }

        this.props.projectsActions.updateProjectAttributes(this.props.entity!.id, { [fieldName]: fieldValue });
    }

    private onEditCompleteMultipleAttrs = (attrs: Dictionary<string | number | undefined>, context: Dictionary<UpdateContext>): void => {
        this.props.projectsActions.updateProjectAttributes(this.props.entity!.id, attrs, context);
    }

    private deleteHistory = (attributeName: string, id: string | undefined): void => {
        this.props.projectsActions.deleteHistory(this.props.entity!.id, attributeName, id);
    }

    private loadHistory = (attributeName?: string) => {
        this.props.projectsActions.loadHistory?.(this.props.entity!.id, attributeName);
    }

    private resetStatus = (statusAttributeName: string): void => {
        this.props.projectsActions.resetProjectStatus(this.props.entity!.id, statusAttributeName);
    }

    private updateSettings = (data: Partial<IInsightsData & ProjectsListStore.ProjectSettings>): void => {
        this.props.projectsActions.updateSettings(this.props.entity!.id, data, () => {
            if (data.calculateCompletedWorkBasedOnReportedTime) {
                this.props.projectsActions.runTaskCompletedWorkRecalculation(this.props.entity!.id);
            }
        });
        this.props.notificationsActions.pushNotification({ message: `Project settings have been changed.` });
    }

    private applyLayout = (layout: Metadata.Layout) => {
        this.props.projectsActions.applyLayout(this.props.entity!.id, layout.id);
        this.props.notificationsActions.pushNotification({ message: `Layout '${layout.name}' applied`, type: Notifications.NotificationType.Info });
    }

    private viewLayout = (layout?: Metadata.Layout) => {
        this.props.layoutsActions.viewLayout(layout?.id);
    }

    private updateDefaultLayout = (updates: Dictionary<Metadata.IUpdateSectionInfo>) => {
        const entity = this.props.entity!;
        if (this.props.isReadonlyMode) {
            this.props.projectsActions.updateSectionsOnClient(entity, updates);
        } else {
            this.props.projectsActions.updateSections(entity.id, updates);
        }
    }

    private updatePinnedViews = (pinnedViews: string[]) => {
        this.props.projectsActions.updatePinnedViews(this.props.entity!.id, pinnedViews);
    }

    private deleteLayout = (layout: Metadata.Layout) => {
        this.props.layoutsActions.removeLayout(layout.id);
        this.props.notificationsActions.pushNotification({ message: `${layout.isView ? 'View' : 'Layout'} '${layout.name}' deleted`, type: Notifications.NotificationType.Info });
    }

    private saveLayout = (layoutId: string | undefined, sections: Metadata.Section[], update: Metadata.IUpdateLayoutInfo, callback?: (layoutId: string) => void) => {
        this.props.layoutsActions.saveLayout(layoutId, sections, update, callback);
        this.props.notificationsActions.pushNotification({
            message: `${update.isView ? 'View' : 'Layout'} '${update.name}' ${layoutId ? 'updated' : 'created'}`,
            type: Notifications.NotificationType.Info
        });
    }

    private cloneProject = () => {
        this.props.projectsActions.cloneProject(this.props.entity!.id);
        this.props.notificationsActions.pushNotification({
            message: this.props.entity?.isArchived
                ? "Project is cloned using a limited dataset from an existing archived project"
                : "Project is cloned",
            type: Notifications.NotificationType.Info
        });
    }

    private removeProject = () => {
        this.props.projectsActions.removeProjects([this.props.entity!.id], true);
    }

    private archiveProject = () => {
        this.props.projectsActions.archiveProjects([this.props.entity!.id], true);
    }

    private setProjectBaseline = (setTasksBaseline: boolean, setKeyDatesBaseline: boolean) => {
        this.props.projectsActions.setProjectBaseline(this.props.entity!.id, setTasksBaseline, setKeyDatesBaseline);
    }

    private updateImage = (logo: File) => {
        this.props.projectsActions.updateImage(this.props.entity!.id, logo);
    }

    private removeImage = () => {
        this.props.projectsActions.removeImage(this.props.entity!.id);
    }

    private updatePriorityAlignment = (strategicPriorityId: string, impact: Impact) => {
        this.props.projectsActions.updatePriorityAlignment(this.props.entity!.id, strategicPriorityId, impact);
    }

    private recalculateAlignmentScore = () => {
        this.props.projectsActions.recalculateAlignmentScore(this.props.entity!.id);
    }

    private updateCalculation = (changes: Partial<ProjectsListStore.ProjectCalculation>) => {
        this.props.projectsActions.updateCalculation(this.props.entity!.id, changes);
    }

    private setFavorite = (isFavorite: boolean) => {
        this.props.projectsActions.setFavorite(this.props.entity!.id, isFavorite);
    }

    private openConfigurationPanel = () => {
        this.setState({ isCofigurationPanelOpen: true });
    }

    private refreshAttributes = (): void => {
        this.props.projectsActions.refreshProjectAttributes(this.props.entity!.id);
    }

    private updateAiInsights = (): void => {
        this.props.projectsActions.updateAiInsights(this.props.entity!.id);
    }

    private isKeyDatesStatusCalculationAuto() {
        const { insights, entity } = this.props;
        return insights.keyDate.statusCalculation === StatusCalculationTypes.Auto && !entity?.insights.keyDateStatusCalculationDisabled;
    }

    private _renderKeyDateImport = (): undefined | ((props: { onDismiss: () => void }) => JSX.Element) => {
        const si = this._getImportSupportedSourceInfos();

        return (props: { onDismiss: () => void }) => <ImportSubentityFromTask {...props}
            projectId={this.props.entity?.id!}
            allSubentities={this.props.entity?.keyDates!}
            subentity={{
                subentityType: EntityType.KeyDate,
                subentityTypeLabel: "Key Date",
                pluralSubentityTypeLabel: "Key Dates",
                subentityCollectionName: nameof("keyDates", this.props.entity)
            }}
            sources={si}
            connectionHelpLinks={{
                [SourceType.Ppmx]: "https://help.ppm.express/94165-task-management/1618080",
                [SourceType.Jira]: "https://help.ppm.express/89435-jira-connection/597316",
                [SourceType.VSTS]: "https://help.ppm.express/89488-azure-devops-connection/how-to-import-key-dates-from-azure-devops?from_search=47313770",
                [SourceType.O365Planner]: "https://help.ppm.express/89434-planner-connection/how-to-import-key-dates-from-planner?from_search=47313770",
                [SourceType.Spo]:
                    "https://help.ppm.express/89437-project-online-connection/541609-how-to-import-project-online-tasks-as-key-dates-to-ppm-express?from_search=47313770",
                [SourceType.P4W]: "https://help.ppm.express/project-for-the-web-connection/1786695-how-to-import-project-for-the-web-tasks-as-key-dates-to-ppm-express",
                [SourceType.MondayCom]: " https://help.ppm.express/mondaycom-connection/1664396-how-to-import-monday-com-tasks-as-key-dates",
                [SourceType.Smartsheet]: "https://help.ppm.express/smartsheet-connection/1664633-how-to-import-smartsheet-tasks-as-key-dates",
                [SourceType.MPPFile]: "https://help.ppm.express/ppm-express-project-publisher/2531617",
            }}
            onImport={this.importKeyDates} />;
    }

    private _renderDeliverableImport = (): undefined | ((props: { onDismiss: () => void }) => JSX.Element) => {
        const si = this._getImportSupportedSourceInfos();

        return (props: { onDismiss: () => void }) => <ImportSubentityFromTask {...props}
            projectId={this.props.entity?.id!}
            allSubentities={this.props.entity?.deliverables!}
            subentity={{
                subentityType: EntityType.Deliverable,
                subentityTypeLabel: "Deliverable",
                pluralSubentityTypeLabel: "Deliverables",
                subentityCollectionName: nameof("deliverables", this.props.entity)
            }}
            sources={si}
            connectionHelpLinks={
                [SourceType.Ppmx, SourceType.Spo, SourceType.P4W, SourceType.MondayCom, SourceType.Smartsheet,
                SourceType.O365Planner, SourceType.VSTS, SourceType.Jira, SourceType.MPPFile]
                    .reduce((p, c) => ({ ...p, [c]: "https://help.ppm.express/89502-ppm-express-how-to-articles/2515902" }), {})}
            onImport={this.importDeliverables} />;
    }

    private _getImportSupportedSourceInfos = (): ISourceInfo<any>[] => {
        const supported = [SourceType.VSTS, SourceType.O365Planner, SourceType.Spo, SourceType.Jira,
        SourceType.MondayCom, SourceType.Smartsheet, SourceType.MPPFile, SourceType.P4W];
        const available = this.props.integrations.getAvailable(supported);
        return this.props.entity!.sourceInfos.filter(_ => ~available.indexOf(_.type)).concat([PpmxConnection]);
    }

    private _onSectionHeaderMiddlePartRender = (section: IContainerSectionInfo): JSX.Element | null => {
        return (
            <EntitySectionWarning warningsTypes={this.props.sectionWarningsTypeMap?.[section.name]}
                warnings={this.props.entity!.warnings} />
        );
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const { warningsTypeMap, insights, entity, timePortalUrl, newTimeTrackingEnabled, projectsActions, layouts, user, isReadonlyMode } = this.props;
        const analyticsProps = { user: user, parentType: EntityType.Project };
        const { entityId, entityActions, actions } = buildActions(entity!.id, isReadonlyMode, analyticsProps, layouts, projectsActions, projectsActions.loadProject);

        const partialUpdateUIControl = buildUpdateUIControl(entity!.id, isReadonlyMode, layouts, {
            updateLayoutUIControl: projectsActions.partialUpdateLayoutUIControl,
            updateUIControl: projectsActions.partialUpdateUIControl,
            updateUIControlOnClient: projectsActions.partialUpdateUIControlOnClient
        });
        const targetedWarningsTypeMap = warningsTypeMap?.targeted;
        const keyDateFields = this.props.fields[EntityType.KeyDate].allIds.map(_ => this.props.fields[EntityType.KeyDate].byId[_]);
        const deliverableFields = this.props.fields[EntityType.Deliverable].allIds.map(_ => this.props.fields[EntityType.Deliverable].byId[_]);
        const projectFields = this.props.fields[EntityType.Project].allIds.map(_ => this.props.fields[EntityType.Project].byId[_]);
        return {
            ['TimelineControl']: {
                datacontext: {
                    timeline: {
                        item: buildTimelineItem(entity!, projectFields, keyDateFields, undefined, true),
                        displayToday: true,
                        startDate: entity!.attributes.StartDate,
                        finishDate: entity!.attributes.FinishDate,
                        renderSegmentContent: renderSegmentContent,
                        renderSegmentTooltipContent: renderIterationSegmentTooltipContent,
                        renderMarkerTooltipContent: renderMarkerTooltipContent
                    }
                },
            },
            ['FieldsArea']: {
                datacontext: {
                    entityId,
                    entityType: EntityType.Project,
                    readOnlyFields: [
                        ...buildReadOnlyStatusFieldsNames(insights.project.statusCalculation, ProjectsListStore.statuses, !entity?.insights.statusCalculationDisabled),
                        ... (this.props.entity?.isPrivate ? [nameof<ProjectsListStore.ProjectAttrs>("Program"), nameof<ProjectsListStore.ProjectAttrs>("Portfolio")] : [])
                    ],
                    warningsTypeMap: targetedWarningsTypeMap && targetedWarningsTypeMap["Details"] && targetedWarningsTypeMap["Details"]["FieldsArea"]
                },
                editProps: {
                    customFieldValidator: { ...validators, ...buildIWithBenefitsValidators() },
                    elementCustomRender: {
                        "Portfolio": (props: IInputProps, state: ProjectsListStore.ProjectInfo, field: Metadata.Field): JSX.Element | null => {
                            return <ProjectPortfoliosPicker {...props} entity={state} />;
                        },
                        ...rendersBuilder(),
                        ...buildIWithBenefitsCustomRenders()
                    },
                },
                actions: {
                    ...entityActions,
                    onEditComplete: this.onEditComplete
                }
            },
            ['TeamMembersControl']: {
                ...buildDataSourceSelect(entity, this.openConfigurationPanel),
                actions: {
                    openConfigurationPanel: this.openConfigurationPanel
                }
            },
            ['ScheduleControl']: {
                ...buildDataSourceSelect(entity, this.openConfigurationPanel),
                actions: {
                    openConfigurationPanel: this.openConfigurationPanel,
                    updateUiControl: partialUpdateUIControl
                }
            },
            ['StatusesControl']: {
                ...buildViewTypeSelect(ViewTypeViews.StatusesControl),
                datacontext: {
                    entityId,
                    entityType: EntityType.Project,
                    statusCalculation: insights.project,
                    statuses: ProjectsListStore.statuses,
                    warningsTypeMap: targetedWarningsTypeMap && targetedWarningsTypeMap["Statuses"] && targetedWarningsTypeMap["Statuses"]["StatusesControl"]
                },
                actions: {
                    ...entityActions,
                    onEditComplete: this.onEditCompleteMultipleAttrs,
                    resetStatus: this.resetStatus,
                    deleteStatusHistory: this.deleteHistory,
                    loadStatusHistory: this.loadHistory,
                }
            },
            ['KeyDatesControl']: {
                settingsBuilder: KeyDatesControl.settingsBuilder,
                headerRender: buildViewTypeHeaderRender(ViewTypeViews.KeyDatesControl),
                datacontext: {
                    readOnlyFields: this.isKeyDatesStatusCalculationAuto() ? [nameof<IKeyDateAttrs>("Status")] : [],
                    editableNativeFieldsForLinkedEntities: !this.isKeyDatesStatusCalculationAuto() ? [nameof<IKeyDateAttrs>("Status")] : []
                },
                actions: buildKeyDateActions(entityId, analyticsProps, actions, projectsActions, keyDateFields, this._renderKeyDateImport())
            },
            ['IterationsControl']: {
                ...buildViewTypeSelect(ViewTypeViews.KeyDatesControl),
                datacontext: {
                    editableNativeFieldsForLinkedEntities: [nameof<IIterationAttrs>("Description")],
                },
                editProps: {
                    elementCustomRender: { ...rendersBuilder() },
                    customFieldValidator: { ...validators },
                },
                actions: buildIterationActions(entityId, analyticsProps, actions, projectsActions)
            },
            ['ResourcePlanControl']: {
                ...buildViewTypeSelect(ViewTypeViews.ResourcePlanControl),
                datacontext: { entityType: EntityType.Project },
                actions: {
                    addResources: (resourceIds, timeframe) =>
                        this.props.projectsActions.addResources(this.props.entity!.id, resourceIds, timeframe),
                    createResource: (name, layoutId, timeframe, auto, startDate, finishDate) =>
                        this.props.projectsActions.createResourceAndAdd(entityId, name, layoutId, timeframe, auto, startDate, finishDate),
                    replaceResources: (prev, next, keepRate, sumUpPlanHours, sumUpActualHours, timeframe) =>
                        this.props.projectsActions.replaceResource(entityId, prev, next, keepRate, sumUpPlanHours, timeframe, sumUpActualHours),
                    removeResources: (ids, timeframe) => this.props.projectsActions.removeResources(entityId, ids, timeframe),
                    updateUIControl: entityActions.updateUIControl
                }
            },
            ['TimeControl']: {
                ...buildTimeControl(entity!, timePortalUrl, this.openConfigurationPanel, newTimeTrackingEnabled),
                actions: { refreshAttributes: this.refreshAttributes }
            },
            ['DependenciesControl']: {
                settingsBuilder: withViewType(Views.List),
                editProps: {
                    elementCustomRender: {
                        [nameof<IDependencyAttrs>("Item")]: (props: IInputProps, state: Dependency, field: Metadata.Field, validator?: Validator): JSX.Element | null => {
                            return <EntityRefInput {...props}
                                inputProps={{ ...field.settings, readOnly: field.isReadonly }}
                                exceptIds={[this.props.entity!.id]}
                                hasIdeation={this.props.hasIdeation}
                                validator={validator} />;
                        }
                    }
                },
                inlineEditProps: {
                    elementCustomRender: {
                        [nameof<IDependencyAttrs>("Item")]: (props: IInputProps, state: Dependency, field: Metadata.Field, validator?: Validator): JSX.Element | null => null                    
                    }
                },
                actions: buildDependencyActions(entityId, analyticsProps, actions, projectsActions)
            },
            ["KPIControl"]: {
                datacontext: { entityType: EntityType.Project },
                actions: { updateUIControl: entityActions.updateUIControl }
            },
            ["RisksControl"]: {
                datacontext: {
                    entityType: EntityType.Project,
                },
                settingsBuilder: withViewType(Views.List),
                actions: buildRiskActions(entityId, analyticsProps, actions, projectsActions)
            },
            ["IssuesControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildIssuesActions(entityId, analyticsProps, actions, projectsActions)
            },
            ["LessonsLearnedControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildLessonLearnedActions(entityId, analyticsProps, actions, projectsActions, this.buildNewLessonLearned)
            },
            ["ActionItemsControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildActionItemActions(entityId, analyticsProps, actions, projectsActions)
            },
            ["KeyDecisionsControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildKeyDecisionActions(entityId, analyticsProps, actions, projectsActions)
            },
            ["SteeringCommitteeControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildSteeringCommitteeActions(entityId, analyticsProps, actions, projectsActions)
            },
            ["ChangeRequestsControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildChangeRequestActions(entityId, analyticsProps, actions, projectsActions)
            },
            ["BudgetControl"]: {
                actions: { updateCalculation: this.updateCalculation }
            },
            ["BenefitsControl"]: {
                actions: { updateCalculation: this.updateCalculation }
            },
            ["StrategicAlignmentControl"]: {
                actions: {
                    updatePriorityAlignment: this.updatePriorityAlignment,
                    recalculateAlignmentScore: this.recalculateAlignmentScore,
                }
            },
            ["KeyResultAssignmentsControl"]: {
                actions: {
                    saveKeyResult: (keyResultAssignment: KeyResultAssignment) => {
                        this.props.projectsActions.saveKeyResult(this.props.entity?.id!, keyResultAssignment);
                        analytics.trackEvent("Add key result to OKR section", this.props.user, { entityName: this.props.entity?.attributes.Name });
                    },
                    deleteKeyResult: (ids: string[]) => {
                        this.props.projectsActions.removeKeyResults(this.props.entity?.id!, ids);
                    }
                }
            },
            ['AiInsightsControl']: {
                actions: {
                    updateAiInsights: this.updateAiInsights,
                    createActionItem: (parentId: string, data: IActionItemAttrs) => {
                        projectsActions.createActionItem(parentId, { attributes: data, externalData: {} });
                        analytics.trackCreate(analyticsProps.user, { itemTitle: data.Name, itemType: EntityType.ActionItem, parentType: analyticsProps.parentType });
                        const actionItemsSection = entity?.sections.find(_ => _.name === SectionNames.ACTION_ITEMS);
                        const actionItemTitle = actionItemsSection?.label ?? 'Action Items';
                        this.props.notificationsActions.pushNotification({ message: `Action Item '${data.Name}' was added to the section '${actionItemTitle}'`, type: NotificationType.Success });
                    }
                },
                helpUrl: "https://help.ppm.express/ppm-insights-ai/2131403",
                buildMenuItems: (!entity?.isArchived && this.props.aiEnabled) ? ({ settings, onChange }) => [{
                    key: 'regenerate',
                    name: 'Regenerate',
                    iconProps: { iconName: 'Sync' },
                    disabled: this.props.isAiInsightsLoading,
                    onClick: (e: any, item?: IContextualMenuItem) => this.updateAiInsights()
                }] : undefined,
            },
            ["PurchaseOrdersControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildPurchaseOrderActions(entityId, analyticsProps, actions, projectsActions)
            },
            ['EmbeddedContentControl']: {
                datacontext: { entityType: EntityType.Project },
                helpUrl: "https://help.ppm.express/89502-ppm-express-how-to-articles/2416583",
                actions: {
                    updateUiControl: entityActions.updateUIControl
                }
            },
            ["InvoicesControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildInvoiceActions(entityId, analyticsProps, actions, projectsActions)
            },
            ["DeliverablesControl"]: {
                settingsBuilder: settings => viewTypeSettingsBuilder(settings, ViewTypeViews.DeliverablesControl.default),
                headerRender: buildViewTypeHeaderRender(ViewTypeViews.DeliverablesControl),
                actions: buildDeliverablesActions(entityId, analyticsProps, actions, projectsActions, deliverableFields, this._renderDeliverableImport())
            },
            ['LinkedRoadmapControl']:
                LinkedRoadmapControl.withLinkedRoadmapSettings(entity, EntityType.Project, user, this.props.isReadonlyMode, entityActions.updateUIControl),
            ["ProjectStagesControl"]: {
                ...buildViewTypeSelect(ViewTypeViews.ProjectStagesControl)
            }
        }
    }
}

const buildTimeControl = (entity: IWithSourceInfos & { isEditable: boolean, isPrivate?: boolean }, timePortalUrl: string, onConnectClick: () => void, newTimeTrackingEnabled: boolean):
    TimeControl.IConfiguration | undefined => entity.isPrivate ? undefined : ({
        settingsBuilder: settings => mergeDeep(settings, { showAllLinkedSummary: settings?.showAllLinkedSummary ?? false }),
        buildMenuItems: ({ settings, onChange }) =>
            newTimeTrackingEnabled
                ? []
                : [{
                    key: 'aggregated',
                    name: 'Aggregated Hours',
                    title: 'Show data from all projects linked to current project on PPM Express | Time side',
                    canCheck: true,
                    checked: !!settings.showAllLinkedSummary,
                    iconProps: { iconName: 'PPMXSigma' },
                    onClick: (e: any, item?: IContextualMenuItem) => onChange({
                        expand: true,
                        update: { showAllLinkedSummary: !item?.checked }
                    })
                }],
        headerRender: () => {
            if (newTimeTrackingEnabled) {
                return <></>;
            }

            const timeConnected = entity?.sourceInfos.find(_ => _.type === SourceType.PpmxTime);
            return <>
                <IconButton
                    key="time"
                    iconProps={{ iconName: "PPMXTimeLogo" }}
                    title="PPM Express | Time Portal"
                    className={`icon-switcher ${timeConnected ? "on" : ""}`}
                    target="_blank"
                    href={timePortalUrl}
                    onClick={(e) => e.stopPropagation()}
                />
                {!timeConnected
                    ? <ActionButton
                        title="Connect"
                        iconProps={{ iconName: "PPMXLink" }}
                        className="link-btn"
                        disabled={!entity.isEditable}
                        onClick={onConnectClick} />
                    : undefined}
            </>
        }
    })

const buildDataSourceSelect = (entity: any, onAddConnection: () => void): IControlConfiguration<any, { dataSource: string }> => ({
    settingsBuilder: settings => mergeDeep(settings, {
        dataSource: settings.dataSource || getDefaultSelected(entity.sourceInfos)?.connectionId
    }),
    headerRender: ({ settings, onChange }) => <DataSourceSelect
        key='ds-select'
        sourceInfos={entity!.sourceInfos}
        selected={settings.dataSource}
        onItemSelected={(connectionId) => onChange({
            expand: true,
            update: { dataSource: connectionId }
        })}
        onAddConnection={onAddConnection}
        isConnectPanelAvailable={entity!.isEditable}
    />
})

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        portfoliosActions: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        programsActions: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        warningsTypeMapsActions: bindActionCreators(WarningsTypeMapsStore.actionCreators, dispatch),
        layoutsActions: bindActionCreators(LayoutsStore.actionCreators.forEntity(entityName), dispatch),
        calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch)
    }
}

export default connect(
    (state: ApplicationState, ownProp: RouteComponentProps<{ id: string }>): StateProps => {
        const warningsTypeMap = state.warningsTypeMaps.maps[entityName] || { common: [], targeted: {} }
        const entity = state.projectsList.activeEntity && state.projectsList.activeEntity.id === ownProp.match.params.id
            ? state.projectsList.activeEntity
            : undefined;
        let sectionWarningsTypeMap = {};
        let warnings: IWarning[] = [];
        if (entity?.sections) {
            const tuple = warningService.filterByEntityConfiguration([...entity.warnings], warningsTypeMap, entity.sections);
            warnings = tuple.warnings;
            sectionWarningsTypeMap = tuple.sectionWarningsTypeMap;
        }

        return {
            user: state.user,
            entity,
            warnings,
            warningsTypeMap,
            sectionWarningsTypeMap,
            fields: state.fields,
            isLoading: state.projectsList.isLoading || state.warningsTypeMaps.isLoading || !!state.layouts[entityName].isApplyingLayout,
            isUpdatingSections: state.projectsList.isUpdatingSections,
            layouts: state.layouts[entityName],
            projectReports: state.tenant.reporting.projectReports.subPacks,
            insights: state.tenant.insights,
            timePortalUrl: state.time.portalUrl,
            integrations: new Integrations(state.tenant.subscription.integrations),
            isReadonlyMode: isInReadonlyMode(state.user, state.tenant, entity),
            hasArchiveProjects: Subscription.contains(state.tenant.subscription, PPMFeatures.ArchiveProjects),
            hasPortfolioManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.PortfolioManagement),
            hasIdeation: Subscription.contains(state.tenant.subscription, PPMFeatures.Ideation),
            isAiInsightsLoading: !!entity?.aiInsights?.isAiInsightsLoading,
            aiEnabled: state.tenant.aiInsights.aiEnabled,
            newTimeTrackingEnabled: state.tenant.timeTracking.globalSettings.newTimeTrackingEnabled
        };
    },
    mergeActionCreators
)(ProjectDetails);