import React from 'react';
import { ComparerBuilder, Dictionary, IExtensibleEntity, ISortingProps } from "../../entities/common";
import * as ViewsStore from '../../store/views';
import { OnItemRender } from '../common/extensibleEntity/EntityDetailsList';
import { EntityChevron } from '../common/extensibleEntity/EntityGroupHeader';
import { Field } from '../../entities/Metadata';
import { HierarchyManager } from './HierarchyManager';

const CHEVRON_WIDTH = 29;
export interface IHierarchyItem { hKey: string; hParentKey?: string; }

type HierarchyManagerProps<T extends { id: string }, TContext> = {
    fieldId: string;
    collapseItemsOnSorting?: boolean;
    allowDrag?: () => boolean;
    isItemDraggable?: (item: T, items: T[]) => boolean;
    isHierarchicalSort?: () => boolean;
    filterRootHierarchyItems?: (items: T[]) => T[];
    getDefaultOutlineLevel?: () => number;
    getItemId: (item: T) => string | null;
    getItemParentId: (item: T) => string | null | undefined;
    getItemIsParent?: (item: T) => boolean;
    getItemCategory?: (item: T) => number;
    isHierarchyView?: (context?: TContext) => boolean;
    getGroupKey?: (item: T) => string;
    supportsOutline?: () => boolean;
}

type HierarchyData<T extends { id: string } & IHierarchyItem> = {
    level?: number;
    item: T;
    childItems: T[];
}

type OrderBy = ViewsStore.IOrderBy | ViewsStore.IOrderBy[];

type SortingStrategy<T> = {
    orderBy?: OrderBy;
    comparerBuilder: ComparerBuilder<T, OrderBy>;
}
type Observer = () => void;

export type THierarchyEntity = { id: string } & IExtensibleEntity;

const DEFAULT_IS_HIERARCHY_VIEW = false;

export class SectionHierarchyManager<T extends THierarchyEntity, TContext = unknown> {
    private _props: HierarchyManagerProps<T, TContext>;
    private _items: (T & IHierarchyItem)[] = [];
    private _allEntities: T[] = [];
    private _shownChildrenCount: number = 0;
    private _expanded: Dictionary<HierarchyData<T & IHierarchyItem>> = {};
    private _isHierarchyView: boolean = DEFAULT_IS_HIERARCHY_VIEW;
    private _context?: TContext;

    constructor(props: HierarchyManagerProps<T, TContext>) {
        this._props = props;
    }

    private observers: Observer[] = [];

    public subscribe(observer: Observer) {
        this.observers.push(observer)
    }

    public unsubscribe(observer: Observer) {
        this.observers = this.observers.filter(_ => _ !== observer);
    }

    private notify() {
        this.observers.forEach(observer => {
            observer();
        })
    }

    getExpandedChildrenCount = () => this._shownChildrenCount;
    getKey = (entity: T) => (entity as any as IHierarchyItem).hKey || entity.id;
    getParentKey = (entity: T) => (entity as any as IHierarchyItem).hParentKey;

    setItems = (entities: T[], allEntities: T[], keepExpanded?: boolean | string[], context?: TContext) => {
        this._shownChildrenCount = 0;
        this._context = context;
        const expandedIds = keepExpanded === true ? this.getExpandedIds() : Array.isArray(keepExpanded) ? keepExpanded : [];
        this._expanded = {};
        this._allEntities = allEntities;
        this._isHierarchyView = this._props.isHierarchyView?.(context) ?? DEFAULT_IS_HIERARCHY_VIEW;

        const filteredEntities = this._isHierarchyView && this._props.filterRootHierarchyItems ? this._props.filterRootHierarchyItems(entities) : entities;
        this._items = HierarchyManager.BuildHierarchyItems(filteredEntities);

        if (this._isHierarchyView) {
            let expandLevel = this._props.getDefaultOutlineLevel?.() || 0;
            expandLevel && this._items.forEach(_ => this._expand(_, expandLevel));
        }

        if (expandedIds.length) {
            const map = this._items.reduce((cum, cur) => ({ ...cum, [cur.id]: cur }), {});
            expandedIds.forEach(_ => map[_] && this._expand(map[_], 0, expandedIds));
        }

        this.notify();
    }

    getExpandedIds = (): string[] => {
        return Object.keys(this._expanded).map(_ => this._expanded[_].item.id);
    }

    getSortingProps = (sorting: ISortingProps): ISortingProps => {
        const isHierarchicalSort = this._props.isHierarchicalSort ? this._props.isHierarchicalSort() : true;
        const disabled = this._isHierarchyView || sorting.disabled;
        return {
            ...sorting,
            orderBy: this._isHierarchyView ? undefined : sorting.orderBy,
            disabled: disabled,
            external: sorting.external || isHierarchicalSort,
            onChange: disabled
                ? undefined
                : orderBy => {
                    if (this._props.collapseItemsOnSorting) {
                        this._shownChildrenCount = 0;
                        this._expanded = {};
                    }

                    sorting.onChange?.(orderBy);
                }
        };
    }

    allowDrag = () => this._props.allowDrag?.();
    onDragStart = (entity: T): void => {
        const e = entity as any as IHierarchyItem;
        const isExpanded = !!this._expanded[e.hKey];
        if (isExpanded) {
            this._collapse(e.hKey);
            this.notify();
        }
    }

    isDragDisabled = (entity: T): boolean => {
        const e = entity as any as IHierarchyItem;
        const isExpanded = !!this._expanded[e.hKey];
        const isItemDraggable = this._props.isItemDraggable === undefined || this._props.isItemDraggable(entity, this._items);
        return isExpanded || !isItemDraggable;
    }

    getFlattened = (sorting: SortingStrategy<T>): (T & IHierarchyItem)[] => {
        return this._sort(sorting, this._items)
            .reduce((cum, cur) => ([...cum, cur, ...this._getSubItems(sorting, cur)]), []);
    }

    private _getSubItems = (sorting: SortingStrategy<T>, item: T & IHierarchyItem): (T & IHierarchyItem)[] => {
        let items = this._expanded[item.hKey]?.childItems || [];
        if (!items.length)
            return items;

        const { getItemCategory } = this._props;
        if (getItemCategory) {
            const map = items.reduce((cum, cur) => { const _ = getItemCategory(cur); return { ...cum, [_]: [...(cum[_] || []), cur] }; }, {});
            items = Object.keys(map).reduce((cum, cur) => ([...cum, ...this._sort(sorting, map[cur])]), []);
        } else
            items = this._sort(sorting, items);

        return items.reduce((cum, cur) => ([...cum, cur, ...this._getSubItems(sorting, cur)]), []);
    }

    private _sort = (sorting: SortingStrategy<T>, items: (T & IHierarchyItem)[]): (T & IHierarchyItem)[] => {
        return this._isHierarchyView || !sorting.orderBy
            ? items
            : [...items].sort(sorting.comparerBuilder(sorting.orderBy));
    }

    private _getHierarchyItem = (entityId: string): T & IHierarchyItem | undefined => {
        const entity = this._items.find(_ => _.id === entityId);
        if (entity) {
            return entity;
        }
        // search IHierarchyItem as child
        for (const key in this._expanded) {
            if (this._expanded.hasOwnProperty(key)) {
                const item = this._expanded[key]?.childItems.find(__ => __.id === entityId);
                if (item) {
                    return item;
                }
            }
        }
        return undefined;
    }

    public isSupportOutline = (): boolean => {
        return this._props.supportsOutline?.() ?? false;
    }

    public expandAll = (): void => {
        this._items.forEach(_ => this._expand(_, Number.MAX_VALUE));
        this.notify();
    }

    public collapseAll = (): void => {
        this._items.forEach(_ => this._collapse(_.hKey));
        this.notify();
    }

    public collapse = (item: T) => {
        const hentity = this._getHierarchyItem(item.id);
        if (!hentity) {
            return;
        }

        this._collapse(hentity.hKey);
        this.notify();
    }

    public expand = (entityId: string, subLevels: number = 0, expandIds: string[] = []): void => {
        const hentity = this._getHierarchyItem(entityId);
        if (!hentity) {
            return;
        }
        this._expand(hentity, subLevels, expandIds);
        this.notify();
    }

    private _expand = (entity: T & IHierarchyItem, subLevels: number = 0, expandIds: string[] = []) => {
        const entityId = this._props.getItemId(entity);
        const filteredEntities = this._allEntities.filter(_ => {
            const id = this._props.getItemId(_);
            const parentId = this._props.getItemParentId(_);
            return id != parentId && parentId == entityId;
        });
        const childItems = HierarchyManager.BuildHierarchyItems(filteredEntities, entity.hKey);

        const parent = entity.hParentKey ? this._expanded[entity.hParentKey] : undefined;

        this._expanded[entity.hKey] = {
            level: parent ? (parent.level || 0) + 1 : 0,
            item: entity,
            childItems
        }
        this._shownChildrenCount += childItems.length;

        if (subLevels > 0) {
            childItems.forEach(_ => this._expand(_, subLevels - 1));
        }
        if (expandIds.length && childItems.length) {
            const map = childItems.reduce((cum, cur) => ({ ...cum, [cur.id]: cur }), {});
            expandIds.forEach(_ => map[_] && this._expand(map[_], 0, expandIds));
        }
    }

    private _collapse = (key: string) => {
        if (!this._expanded[key])
            return;

        const nodes = this._expanded[key].childItems;
        delete this._expanded[key];
        nodes.forEach(_ => this._collapse(_.hKey));
        this._shownChildrenCount -= nodes.length;
    }

    private _isExpanded = (entity: T & IHierarchyItem) => !!this._expanded[entity.hKey];

    public getHierarchyInfo = (entity: T & IHierarchyItem) => ({
        isExpanded: this._isExpanded(entity),
        isParent: this._props.getItemIsParent
            ? this._props.getItemIsParent(entity)
            : this._allEntities.find(_ => this._props.getItemParentId(_) === this._props.getItemId(entity)),
        outlineLevel: entity.hParentKey ? (this._expanded[entity.hParentKey]?.level || 0) + 1 : 0
    })

    public toggle = (entity: T & IHierarchyItem) => {
        if (this._isExpanded(entity)) {
            this._collapse(entity.hKey)
        } else {
            this._expand(entity);
        }

        this.notify();
    }

    public isHierarchyField = (field: Field) => field.id === this._props.fieldId;

    public getContext = () => this._context;
}

export const useHierarchy = <T extends THierarchyEntity,>(hierarchy: SectionHierarchyManager<T>,
    sorting: ISortingProps,
    comparerBuilder: ComparerBuilder<T, OrderBy>,
    onItemRender: OnItemRender<T> | undefined) => {
    const onHierarchyItemRender: OnItemRender<T> = React.useCallback(
        (entity: T & IHierarchyItem, index, field, defaultRender) => {
            const content = onItemRender
                ? onItemRender!(entity, index, field, defaultRender)
                : defaultRender();

            if (!hierarchy.isHierarchyField(field)) {
                return content;
            }

            const { outlineLevel, isExpanded, isParent } = hierarchy.getHierarchyInfo(entity);
            return <div
                className={`hierarchy-col ${isParent ? 'parent' : ''}`}
                style={{ paddingLeft: (outlineLevel + (isParent ? 0 : 1)) * CHEVRON_WIDTH }}>
                {
                    isParent && <EntityChevron
                        isCollapsed={!isExpanded}
                        onClick={e => {
                            e.stopPropagation();
                            hierarchy.toggle(entity);
                        }} />}
                {
                    content
                }
            </div>;
        }, [hierarchy, onItemRender]);

    const strategy = {
        orderBy: sorting.orderBy,
        comparerBuilder
    };

    const [entities, setEntities] = React.useState(hierarchy.getFlattened(strategy));

    React.useEffect(() => {
        const onHierarchyChange = () => {
            setEntities(hierarchy.getFlattened(strategy));
        }
        hierarchy.subscribe(onHierarchyChange);
        return () => hierarchy.unsubscribe(onHierarchyChange);
    }, [hierarchy, strategy.orderBy]);

    React.useEffect(() => {
        setEntities(hierarchy.getFlattened(strategy));
    }, [strategy.orderBy]);

    return {
        entities: entities,
        sorting: hierarchy.getSortingProps(sorting),
        onItemRender: onHierarchyItemRender,
        getKey: hierarchy.getKey
    }
}

export const chainMerger = <T, U, Z>(obj: T, mutator1: (obj: T) => U, mutator2: (obj: T & U) => Z): T & U & Z => {
    const withU = { ...obj, ...mutator1(obj) };
    return { ...withU, ...mutator2(withU) };
}

export type HierarchyProps<T extends THierarchyEntity> = {
    hierarchy: SectionHierarchyManager<T>;
    comparerBuilder: ComparerBuilder<T>;
}
