import * as React from 'react';
import * as Metadata from "../../entities/Metadata";
import { ProjectFilterValue, FilterHelper, LocationState } from '../../store/project/filters';
import { actionCreators } from '../../store/filters';
import { ProjectInfo } from '../../store/ProjectsListStore';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { EntityType } from '../../entities/common';
import { bindActionCreators } from 'redux';
import { Portfolio } from '../../store/PortfoliosListStore';
import { default as GenericEntitiesFilter } from '../common/EntitiesFilter';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UserState } from "../../store/User";
import { contains, CommonOperations } from "../../store/permissions";
import { Program } from '../../store/ProgramsListStore';
import * as H from 'history';

export const onlyMyPrefilterId = "only-my";
type StateProps = {
    user: UserState;
    activeFilter?: Metadata.IFilter<ProjectFilterValue>;
    filters: Metadata.IFilter<ProjectFilterValue>[];
    layouts: Metadata.Layout[];
}
type ActionProps = {
    actions: ReturnType<typeof actionCreators.forEntity>
}
type OwnProps = {
    isFilterPanelOpen: boolean;
    toggleFilterPanel: () => void;
    activeFilterId?: string;
    activePrefilterId?: string;
    onFilterChange: (projects: ProjectInfo[], filterId: string | undefined, prefilterId: string | undefined) => void;

    fields: Metadata.Field[];
    projects: ProjectInfo[];
    programs: Program[];
    portfolios: Portfolio[];
}

type Props = StateProps & ActionProps & OwnProps & RouteComponentProps<any>;

const EntitiesFilter = GenericEntitiesFilter<ProjectInfo>();

const ProjectsFilter = (props: Props) => {
    const getFilterById = (id?: string | null) => id ? props.filters.find(_ => _.id === id) : undefined;

    const urlHelper = useUrlHelper(props.history, props.location);

    const stateFilter = props.location.state as LocationState;
    const urlFilterId = urlHelper.getUrlFilterId();
    const initFilterId = React.useMemo(
        () => stateFilter?.filter?.id
            ?? urlFilterId
            ?? props.activeFilterId
            ?? Metadata.Filter.getAutoFilter(props.filters)?.id,
        []);
    const [filterId, setFilterId] = React.useState(initFilterId);

    const urlPreFilterId = urlHelper.getPreFilterId();
    const initPreFilter = stateFilter?.preFilterId
        ?? urlPreFilterId
        ?? props.activePrefilterId;
    const [preFilterId, setPreFilterId] = React.useState(initPreFilter);

    const activeFilter = filterId === Metadata.NEW_ID && stateFilter?.filter?.id === Metadata.NEW_ID
        ? stateFilter.filter
        : getFilterById(filterId);

    const preFilterOptions = React.useMemo(() => getPreFilterOptions(props.user.id), [props.user.id]);
    const preFilter = React.useMemo(
        () => Metadata.PreFilter.create(preFilterOptions, active => setPreFilterId(active?.key)),
        [preFilterOptions, urlHelper, activeFilter]);
    const canManageConfiguration = contains(props.user.permissions.common, CommonOperations.ConfigurationManage);
    const entityFilterHelper = React.useMemo(
        () => new FilterHelper({
            portfolios: props.portfolios,
            programs: props.programs,
            projects: props.projects,
            projectFields: props.fields,
            layouts: props.layouts
        }),
        [props.portfolios, props.programs, props.projects, props.fields, props.layouts]);

    const updateFilter = (filter?: Metadata.IFilter<ProjectFilterValue>) => {
        if (!filter) {
            return;
        }

        props.actions.updateFilter(filter);
    }

    React.useEffect(() => {
        if (!stateFilter) {
            return;
        }
        
        if (stateFilter.filter) {
            props.actions.updateFilter(stateFilter.filter);
        }

        setFilterId(stateFilter.filter?.id);
        setPreFilterId(stateFilter.preFilterId ?? undefined);
    }, [stateFilter]);

    React.useEffect(() => {
        const isFilterChanged = (activeFilter?.id ?? null) !== urlFilterId;
        const isPreFilterChanged = (preFilterId ?? null) !== urlPreFilterId;
        if (isFilterChanged || isPreFilterChanged) {
            urlHelper.openFilter({
                filterId: activeFilter?.id,
                preFilterId: preFilterId ?? null
            });
        }
    }, [activeFilter?.id, urlFilterId, urlPreFilterId, preFilterId, urlHelper]);

    React.useEffect(() => {
        const isItemVisible = isItemVisibleBuilder(entityFilterHelper, props.fields, preFilter, activeFilter, preFilterId);
        props.onFilterChange(props.projects.filter(isItemVisible), filterId, preFilterId);
    }, [props.projects, activeFilter, preFilterId]);

    React.useEffect(() => {
        if (!activeFilter) {
            setFilterId(props.activeFilterId ?? Metadata.Filter.getAutoFilter(props.filters)?.id);
        }
    }, []);
    
    return <EntitiesFilter
        canManageConfiguration={canManageConfiguration}
        isFilterPanelOpen={props.isFilterPanelOpen}
        toggleFilterPanel={props.toggleFilterPanel}
        preFilter={preFilter}
        preFilterId={props.activePrefilterId}
        activeFilter={activeFilter}
        onActiveFilterChanged={setFilterId}
        onFilterChanged={updateFilter}
        entityType={EntityType.Project}
        entityFilterHelper={entityFilterHelper}
        suppressNavigation
    />
}

const getPreFilterOptions = (userId: string | null): Metadata.PreFilterOption<ProjectInfo>[] => [
    { key: onlyMyPrefilterId, name: "Only Mine", predicate: _ => !!_.attributes.Manager && !!_.attributes.Manager.find(m => m.id === userId) },
    { key: "favorite", name: "Favorite", predicate: _ => _.isFavorite }
]

export const urlHelperBuilder = (history: React.MutableRefObject<H.History | undefined>,
    location: React.MutableRefObject<H.Location | undefined>,
    query: URLSearchParams) => ({
        getUrlFilterId: () => query.get("filter") || null,
        getPreFilterId: () => query.get("prefilter") || null,
        openFilter: ({ filterId, preFilterId }: { filterId?: string, preFilterId?: string | null }) => {
            if (filterId !== undefined) {
                query.set('filter', filterId);
            }
            if (preFilterId !== undefined) {
                if (preFilterId) {
                    query.set('prefilter', preFilterId);
                } else {
                    query.delete('prefilter');
                }
            }

            history.current?.replace({
                ...location.current,
                search: query.toString(),
                state: undefined
            });
        }
    });

export const useUrlHelper = (history: H.History, location: H.Location) => {
    const { search } = location;
    const historyRef = React.useRef<H.History>();
    const locationRef = React.useRef<H.Location>();

    React.useEffect(() => {
        historyRef.current = history;
        locationRef.current = location;
    }, [location, history]);

    const urlHelper = React.useMemo(
        () => urlHelperBuilder(historyRef, locationRef, new URLSearchParams(search)),
        [search]);

    return urlHelper;
}

const isItemVisibleBuilder = (entityFilterHelper: FilterHelper,
    fields: Metadata.Field[],
    preFilter: Metadata.PreFilter<ProjectInfo>,
    activeFilter?: Metadata.IFilter<ProjectFilterValue>,
    preFilterId?: string) => (item: ProjectInfo): boolean => {
        if (Metadata.PreFilter.isItemVisible(preFilter, item, preFilterId)) {
            return false;
        }

        if (activeFilter) {
            const filterValue = activeFilter.value;

            const allAttributes = entityFilterHelper.getFilterAttributes(fields);

            for (const type in filterValue) {
                const validateItem = entityFilterHelper
                    .helpersMap[type]
                    .validateItem(item, filterValue[type], allAttributes.filter(_ => _.type === type));
                if (!validateItem) {
                    return false;
                }
            }
        }

        return true;
    }

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const filters = state.filters[EntityType.Project];
    return {
        filters: filters.allIds.map(_ => filters.byId[_]),
        activeFilter: ownProps.activeFilterId ? filters.byId[ownProps.activeFilterId] : undefined,
        user: state.user,
        layouts: state.layouts[EntityType.Project].allIds.map(_ => state.layouts[EntityType.Project].byId[_])
    }
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        actions: bindActionCreators(actionCreators.forEntity(EntityType.Project), dispatch)
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps, mergeActionCreators)(ProjectsFilter));