import * as React from 'react';
import { Spinner, SpinnerSize, Toggle } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { EntityProcessInfo, ProcessInfo, actionCreators } from "../../store/ProjectsListStore";
import EntityCreation from '../common/extensibleEntity/EntityCreation';
import { EntityType, ServerEntityType, entityTypeLabelMap } from '../../entities/common';
import { ApplicationState } from '../../store';
import { TenantState } from '../../store/Tenant';
import LabellableComponent from '../common/LabellableComponent';
import EntityPicker from '../common/inputs/EntityPicker';
import { IFindResult } from '../common/inputs/EntityPickerInput';
import { cancellableGet, get } from '../../fetch-interceptor';
import { Validator } from '../../validation';
import OverlayComponent from '../common/OverlayComponent';

interface OwnProps {
    onDismiss: () => void;
    openOnComplete: boolean;
    showConfigureConnectionsOption: boolean;
    isPrivateProject?: boolean;
}

interface StateProps {
    tenant: TenantState;
}

type ProjectCreationProps = typeof actionCreators & OwnProps & StateProps;

const validators = {
    process: Validator.new().required().build()
};

const ProjectCreation = (props: ProjectCreationProps) => {
    const [configureConnections, setConfigureConnections] = React.useState(props.showConfigureConnectionsOption);
    const [processInfo, setProcessInfo] = React.useState<EntityProcessInfo>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        const request = cancellableGet<EntityProcessInfo>('api/process/default');
        let isCancelled = false;
        setIsLoading(true);
        request.promise
            .then(_ => setProcessInfo({ id: _.id, name: _.name, entityLayout: _.entityLayout }))
            .catch(_ => {
                if (!isCancelled) {
                    console.error(_);   
                }
            })
            .finally(() => setIsLoading(false));
        
        return () => {
            request.cancelTokenSource.cancel();
            isCancelled = true;
        };        
    }, []);

    return <EntityCreation
        entityType={EntityType.Project}
        entityTypeLabel={props.isPrivateProject ? entityTypeLabelMap[EntityType.PrivateProject].singular : undefined}
        onDismiss={props.onDismiss}
        hideTitle
        disabled={!validators.process.isValid(processInfo)}
        defaultLayoutId={processInfo?.entityLayout?.id}
        onSave={(name, layoutId) => props.createProject(name, layoutId, !!props.isPrivateProject, configureConnections, props.openOnComplete, processInfo!.id)}>
            {(updateIsDirty: (isDirty: boolean) => void) => <>
                {props.showConfigureConnectionsOption && <div className="grid-item">
                    <LabellableComponent label='' className="field-container">
                        <Toggle className="two-column"
                            checked={configureConnections}
                            onChange={(e: any, c?: boolean) => setConfigureConnections(!!c)}
                            label="Configure Project Connections" onText='Yes' offText='No' />
                    </LabellableComponent>
                </div>}
                <span>Select a process and a layout that will be applied to this {props.isPrivateProject ? entityTypeLabelMap[EntityType.PrivateProject].singular : entityTypeLabelMap[EntityType.Project].singular}</span>
                <LabellableComponent className="field-container spinner-container" label="Process" required>
                    <OverlayComponent isOverlayed={isLoading}>
                        <EntityPicker
                            value={processInfo}
                            searchPath="api/process/find"
                            filter={{ entityType: ServerEntityType.Project }}
                            inputProps={{ required: true }}
                            onEditComplete={_ => {
                                const result = _ as IFindResult
                                if (!result) {
                                    setProcessInfo(undefined);
                                    updateIsDirty(true);
                                    return;
                                }
                                setProcessInfo({ id: result.id, name: result.name, entityLayout: result.attributes?.EntityLayout });
                                updateIsDirty(false);
                            }}
                        />
                    </OverlayComponent>
                    {isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -46, width: 30, position: 'absolute' }} />}
                </LabellableComponent>
            </>}
    </EntityCreation>;
}

function mapStateToProps(state: ApplicationState) {
    return {
        tenant: state.tenant
    };
}

export default connect(mapStateToProps, actionCreators)(ProjectCreation)