import { get, post } from './../fetch-interceptor';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { push, RouterAction } from 'react-router-redux';
import { IEntityStore, StoreHelper, IDeletionResult } from './services/storeHelper';
import { ppmxTaskConnectionId, EntityType } from "../entities/common";
import { defaultCatch } from "./utils";
import { TasksOperationsFactory, ITasksState } from './tasks';
import { GroupsOperationsFactory, GroupsState } from './groups';
import { CreateProjectSuccessAction, ProjectInfo } from './ProjectsListStore';
import { Section } from "../entities/Metadata";
import { MetadataService, UpdateUIControlInfo, ActionsBuilder } from './services/metadataService';
import { IEntityStage, sortByRank } from '../entities/Subentities';

const namespace = 'ARCHIVED_PROJECT';
const tasksStore = TasksOperationsFactory<string, ProjectInfo, ArchivedProjectsListState>(namespace, EntityType.ArchivedProject);
const groupsStore = GroupsOperationsFactory("externaltask/group", EntityType.ArchivedProject);

export interface ArchivedProjectsListState extends IEntityStore<ProjectInfo> {
    isLoading: boolean;
    isListLoading: boolean;
    isTasksLoading: boolean;
    isListUpdating: boolean;
    isUpdatingSections: boolean;
    deletionResult?: IDeletionResult[];

    tasks: ITasksState;
    groups: GroupsState;
}

export interface RequestProjectsAction {
    type: 'REQUEST_ARCHIVED_PROJECTS';
}

interface ReceivedProjectsAction {
    type: 'RECEIVED_ARCHIVED_PROJECTS';
    projects: ProjectInfo[];
}

interface LoadProject {
    type: "LOAD_ARCHIVED_PROJECT";
    id?: string;
}

interface ReceivedDeleteProjectResultAction {
    type: 'RECEIVED_REMOVE_ARCHIVED_PROJECT_RESULT';
    deletionResult?: IDeletionResult[];
}

interface ReceivedProject {
    type: "RECEIVED_ARCHIVED_PROJECT";
    project: ProjectInfo;
}

interface UpdateSectionAction {
    type: 'UPDATE_ARCHIVED_PROJECT_SECTION_SUCCESS';
    projectId: string;
    sections: Section[];
}

interface UpdatePinnedViewsAction {
    type: 'UPDATE_ARCHIVED_PROJECT_PINNED_VIEWS_SUCCESS';
    projectId: string;
    pinnedViews: string[];
}

interface UpdateUIControlAction {
    type: 'UPDATE_ARCHIVED_PROJECT_UICONTROL_SUCCESS';
    uiControlInfo: UpdateUIControlInfo;
}

type KnownAction = RequestProjectsAction
    | ReceivedProjectsAction
    | LoadProject
    | ReceivedProject
    | ReceivedDeleteProjectResultAction
    | UpdateSectionAction
    | UpdatePinnedViewsAction
    | UpdateUIControlAction;

export const defaultActionCreators = {
    loadProject: (projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<ProjectInfo>(`api/archivedproject/${projectId}`)
            .then(data => dispatch({ type: 'RECEIVED_ARCHIVED_PROJECT', project: data }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: 'LOAD_ARCHIVED_PROJECT', id: projectId });
    },
    requestProjects: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<ProjectInfo[]>(`api/archivedproject`)
            .then(data => dispatch({ type: 'RECEIVED_ARCHIVED_PROJECTS', projects: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_ARCHIVED_PROJECTS' });
    },
    removeProjects: (ids: string[], redirectBack?: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        post<IDeletionResult[]>(`api/archivedproject/bulkDelete`, { ids })
            .then(data => {
                dispatch({ type: "RECEIVED_REMOVE_ARCHIVED_PROJECT_RESULT", deletionResult: data });
                if (redirectBack) {
                    dispatch(push('/archivedProjects'));
                }
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: "LOAD_ARCHIVED_PROJECT" });
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_ARCHIVED_PROJECT_RESULT" });
    },
    cloneProject: (projectId: string): AppThunkAction<CreateProjectSuccessAction | RouterAction> => (dispatch, getState) => {
        post<ProjectInfo>(`api/archivedproject/${projectId}/clone`, {})
            .then(data => {
                dispatch(<CreateProjectSuccessAction>{ type: 'CREATE_PROJECT_SUCCESS', project: data });
                dispatch(push(`/project/${data.id}`, {}));
            })
            .catch(defaultCatch(dispatch));
    },
    updateSectionsOnClient: ActionsBuilder.buildEntityUpdateSectionsOnClient((projectId, sections, dispatch) => dispatch({
        type: 'UPDATE_ARCHIVED_PROJECT_SECTION_SUCCESS',
        projectId,
        sections
    })),
    updateUIControlOnClient: ActionsBuilder.buildEntityUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
        type: 'UPDATE_ARCHIVED_PROJECT_UICONTROL_SUCCESS',
        uiControlInfo
    })),
    partialUpdateUIControlOnClient: ActionsBuilder.buildEntityPartialUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
        type: 'UPDATE_ARCHIVED_PROJECT_UICONTROL_SUCCESS',
        uiControlInfo
    })),
};

const unloadedState: ArchivedProjectsListState = {
    byId: {},
    allIds: [],
    isLoading: false,
    isListLoading: false,
    isTasksLoading: false,
    isListUpdating: false,
    isUpdatingSections: false,

    tasks: {
        ...StoreHelper.create([]),
        isLoading: false,
        isListLoading: false
    },
    groups: {
        ...StoreHelper.create([]),
        isLoading: false
    },
};

export const defaultReducer: Reducer<ArchivedProjectsListState> = (state: ArchivedProjectsListState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ARCHIVED_PROJECTS':
            {
                return {
                    ...state,
                    isListLoading: true
                };
            }
        case 'RECEIVED_ARCHIVED_PROJECTS':
            {
                return {
                    ...state,
                    ...StoreHelper.create(action.projects),
                    isListLoading: false
                };
            }
        case 'RECEIVED_ARCHIVED_PROJECT':
            if (state.activeEntity?.id === action.project.id && action.project.tasks === undefined) {
                //keep project tasks as those are not embedded into project and therefore are not returned along with it
                action.project.tasks = state.activeEntity?.tasks;
                const tasksScheduleData = state.activeEntity.calculation.scheduleData?.[ppmxTaskConnectionId];
                if (tasksScheduleData) {
                    action.project.calculation.scheduleData[ppmxTaskConnectionId] = tasksScheduleData;
                }
            }

            if (state.activeEntity?.id === action.project?.id) {
                action.project.changeHistory = state.activeEntity.changeHistory;
            }

            const project = {
                ...action.project, 
                stages: action.project.stages.sort((a: IEntityStage, b: IEntityStage) => sortByRank(a, b, "Rank"))
            };

            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, project),
                activeEntity: state.activeEntityId === action.project.id ? project : state.activeEntity,
                isLoading: false
            };
        case 'RECEIVED_REMOVE_ARCHIVED_PROJECT_RESULT':
            let newState = state;
            if (action.deletionResult && action.deletionResult.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };
        case 'LOAD_ARCHIVED_PROJECT':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: state.activeEntity && state.activeEntity.id === action.id ? state.activeEntity : undefined,
                isLoading: true
            };
        case 'UPDATE_ARCHIVED_PROJECT_SECTION_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.projectId,
                        (project: ProjectInfo) => Object.assign({}, project, { sections: action.sections })),
                    isUpdatingSections: false,
                };
            }
        case 'UPDATE_ARCHIVED_PROJECT_PINNED_VIEWS_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.projectId,
                        (project: ProjectInfo) => Object.assign({}, project, { pinnedViews: action.pinnedViews })),
                };
            }
        case 'UPDATE_ARCHIVED_PROJECT_UICONTROL_SUCCESS':
            {
                return StoreHelper.applyHandler(state, action.uiControlInfo.entityId,
                    (project: ProjectInfo) => MetadataService.UpdateUIControlSettings(project, action.uiControlInfo));
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

export const reducer: Reducer<ArchivedProjectsListState> = (state: ArchivedProjectsListState = unloadedState, incomingAction: Action) => {
    return defaultReducer(
        tasksStore.reducer(
            {
                ...state,
                groups: groupsStore.reducer(state.groups, incomingAction),
            },
            incomingAction),
        incomingAction
    );
}

export const actionCreators = {
    ...defaultActionCreators,
    ...tasksStore.actionCreators,
};

export const groupsCreators = groupsStore.actionCreators;