import * as React from "react";
import { BaseFilterValue, IFilter, Filter, IFilterHelper, FilterAttribute, Field, IActiveFilter, getLabel, IEntityFilterHelper, Layout } from "../../entities/Metadata";
import { ProjectInfo, ProjectAttrs } from "../ProjectsListStore";
import * as ProjectUtils from "./utils";
import { Dictionary, IUserInfo, StatusCategory, IWithLayout, EntityType, Progress } from "../../entities/common";
import { DisplayFieldService, InputsService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import { Option, OptionsPickerProps } from "../../components/common/inputs/OptionsPicker";
import { SourceType, SourceTypeMap } from "../ExternalEpmConnectStore";
import { nameof, namesof } from "../services/metadataService";
import { Portfolio } from "../PortfoliosListStore";
import { Program } from "../ProgramsListStore";
import { toPercent } from "../../components/utils/common";
import * as StatusDescriptorFactory from "../../entities/StatusDescriptorFactory";
import { distinctByKey } from './../../components/utils/common';

export interface ProjectFilterValue extends BaseFilterValue {
    portfolioId?: string[];
    programId?: string[];
    projectId?: string[];
    processId?: string[];
    linkedSystem?: string[];
    percentComplete?: { from: number, to: number };
    layoutId?: string[];
    projectVisibility?: string[];
    projectStageId?: string[];
    [key: string]: any;
}

const projectVisibilityMap: Dictionary<{ label: string, value: boolean }> = {
    "private": { label: "Private", value: true },
    "public": { label: "Public", value: false }
}

export class ActiveFilter implements IActiveFilter {
    private readonly _filter: IFilter<ProjectFilterValue>;

    constructor(name?: string) {
        this._filter = Filter.empty(name);
    }

    public withPortfolio(id: string): this {
        this._filter.value.portfolioId = [id];
        this._filter.attributeNames.push({ name: 'portfolioId', type: "portfolioId" });
        return this;
    }

    public withProgram(id: string): this {
        this._filter.value.programId = [id];
        this._filter.attributeNames.push({ name: 'programId', type: "programId" });
        return this;
    }

    public withProject(id: string): this {
        this._filter.value.projectId = [id];
        this._filter.attributeNames.push({ name: 'projectId', type: "projectId" });
        return this;
    }

    public withProcess(id: string): this {
        this._filter.value.processId = [id];
        this._filter.attributeNames.push({ name: 'processId', type: "processId" });
        return this;
    }

    public withStatusesOfCategory(category: StatusCategory, projectFields: Field[]): this {
        if (!this._filter.value.attributes) {
            this._filter.value.attributes = new Dictionary<any[]>();
        }
        const attrName = nameof<ProjectAttrs>('OverallStatus');
        const projectStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Project, projectFields);
        const categoryStatuses = projectStatusDescriptor?.getCategoryOptions(category).map(_ => _.name);
        this._filter.value.attributes[attrName] = categoryStatuses;
        this._filter.attributeNames.push({ name: attrName, type: "attributes" });
        return this;
    }

    public withProgress(progress: Progress): this {
        if (!this._filter.value.attributes) {
            this._filter.value.attributes = new Dictionary<any[]>();
        }
        const attrName = nameof<ProjectAttrs>('Progress');
        this._filter.value.attributes[attrName] = [progress.toString()];
        this._filter.attributeNames.push({ name: attrName, type: "attributes" });
        return this;
    }

    public withManager(userInfo: IUserInfo): this {
        if (!this._filter.value.attributes) {
            this._filter.value.attributes = new Dictionary<any[]>();
        }
        this._filter.value.attributes['Manager'] = [userInfo];
        this._filter.attributeNames.push({ name: 'Manager', type: "attributes" });
        return this;
    }

    public withTag(tag: string): this {
        if (!this._filter.value.attributes) {
            this._filter.value.attributes = new Dictionary<any[]>();
        }
        const attrName = 'Tags';
        this._filter.value.attributes[attrName] = [tag];
        this._filter.attributeNames.push({ name: attrName, type: "attributes" });
        return this;
    }

    public withAttributes(attrNames: string[]): this {
        let attributes = this._filter.attributeNames.filter(_ => _.type === "attributes");
        attrNames.forEach(name => {
            let attribute = attributes.find(attr => attr.name === name);
            if (!attribute) {
                this._filter.attributeNames.push({ name: name, type: "attributes" })
            }
        });
        return this;
    }

    public build(): IFilter<ProjectFilterValue> {
        return this._filter;
    }

    public buildLocationState = (preFilterId?: string | null): LocationState => {
        return {
            filter: this._filter,
            preFilterId: preFilterId
        };
    }
}

export type LocationState = {
    filter?: IFilter<BaseFilterValue>;
    preFilterId?: string | null;
}

export type ProjectFilterAttribute = FilterAttribute<ProjectFilterValue>;

export type FilterHelperProps = {
    portfolios: Portfolio[];
    projects: ProjectInfo[];
    projectFields: Field[];
    programs: Program[];
    layouts: Layout[];
}

export class FilterHelper implements IEntityFilterHelper<ProjectInfo> {
    public getFilterAttributes = (fields: Field[]): FilterAttribute<ProjectFilterValue>[] => {
        let attrs = fields
            .filter(_ => !(_.isNative && _.name === nameof<ProjectAttrs>("Portfolio"))
                && !(_.isNative && _.name === nameof<ProjectAttrs>("Program"))
                && !(_.isNative && _.name === nameof<ProjectAttrs>("Stage")))
            .map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<ProjectFilterValue>);

        attrs.push({ type: "portfolioId", name: "portfolioId", displayName: "Portfolio", value: undefined });
        attrs.push({ type: "projectId", name: "projectId", displayName: "Project", value: undefined });
        attrs.push({ type: "programId", name: "programId", displayName: "Program", value: undefined });
        attrs.push({ type: "processId", name: "processId", displayName: "Process", value: undefined });
        attrs.push({ type: "linkedSystem", name: "linkedSystem", displayName: "Linked System", value: undefined });
        attrs.push({ type: "percentComplete", name: "percentComplete", displayName: "% Complete", value: undefined });
        attrs.push({ type: "layoutId", name: "layoutId", displayName: "Layout", value: undefined });
        attrs.push({ type: "projectVisibility", name: "projectVisibility", displayName: "Project Visibility", value: undefined });
        attrs.push({ type: "projectStageId", name: "projectStageId", displayName: "Project Stage", value: undefined  })
        return attrs;
    }

    private attributes: IFilterHelper<ProjectFilterValue, ProjectInfo> = {
        buildFilterElement: (attr: FilterAttribute<ProjectFilterValue>,
            filter: IFilter<ProjectFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const field: Field = attr.value;
            const value = filter.value?.attributes?.[field.name];

            return DisplayFieldService.buildFieldMultiSelectInput(field, value, changed => onFilterEditComplete(attr.type, field.name, changed));
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            let newValue: Dictionary<any> = {};
            Object.keys(typeValue).forEach(vk => {
                if (vk !== attrName) {
                    newValue[vk] = typeValue[vk];
                }
            });
            return newValue;
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            if (!oldValue) {
                oldValue = {};
            }
            const tmpValue = Object.assign({}, oldValue);
            tmpValue[attrName] = value;
            return tmpValue;
        },
        getAttributeValues: (value: any): string[] => FieldsService.getAttributeDisplayValues(this._props.projectFields, value),
        validateItem: (item: ProjectInfo, filterValue: any, attributes: any[]): boolean => {
            if (!filterValue) {
                return false;
            }
            for (var key in filterValue) {
                if (filterValue.hasOwnProperty(key)) {
                    if (filterValue[key] == undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                        continue;
                    }

                    const attribute = attributes.find(_ => _.name === key);
                    if (!attribute) {
                        return false;
                    }
                    const field = attribute.value;
                    const value = item.attributes[key];

                    if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
    private portfolioId: IFilterHelper<ProjectFilterValue, ProjectInfo> =
        {
            buildFilterElement: (attr: FilterAttribute<ProjectFilterValue>,
                filter: IFilter<ProjectFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const portfolioOptions: Option[] = this._props.portfolios.map(_ => ({ key: _.id, text: _.attributes.Name }));
                const selected: Option[] = [];

                filter.value?.portfolioId?.forEach((id: string) => {
                    const opt = portfolioOptions.find(_ => _.key === id);
                    if (opt !== undefined) {
                        selected.push(opt);
                    }
                });

                const itemsProps: Partial<OptionsPickerProps> = {
                    onChange: (opts?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "portfolioId",
                            opts ? opts.map(_ => _.key) : undefined);
                    },
                    onResolveSuggestions: (filter: string, selectedItems?: Option[]) => {
                        let res: Option[] =
                            portfolioOptions.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                        if (selectedItems) {
                            res = res.filter((opt: Option) => selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return new Promise<Option[]>((resolve, reject) => {
                            resolve(res);
                        });
                    },
                    selectedItems: selected
                };

                return InputsService.buildOptionsPicker(itemsProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                const values: string[] = [];
                const array: string[] = value;
                array.forEach(id => {
                    const portfolio = this._props.portfolios.find(_ => _.id === id);
                    if (portfolio) {
                        values.push(portfolio.attributes.Name);
                    }
                });
                return values;
            },
            validateItem: (item: ProjectInfo, filterValue: any): boolean => {
                if (!filterValue || (<string[]>filterValue).length === 0) {
                    return true;
                }

                const portfolioIds = filterValue as string[];
                return item.portfolioViaProgram.map(_ => _.portfolio.id)
                    .concat(item.attributes.Portfolio.map(_ => _.id))
                    .filter(_ => portfolioIds.indexOf(_) !== -1).length > 0;
            }
        }
    private programId: IFilterHelper<ProjectFilterValue, ProjectInfo> =
        {
            buildFilterElement: (attr: FilterAttribute<ProjectFilterValue>,
                filter: IFilter<ProjectFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const programOptions: Option[] = this._props.programs.map(_ => ({ key: _.id, text: _.attributes.Name }));
                const selected: Option[] = [];

                filter.value?.programId?.forEach((id: string) => {
                    const opt = programOptions.find(_ => _.key === id);
                    if (opt !== undefined) {
                        selected.push(opt);
                    }
                });

                const itemsProps: Partial<OptionsPickerProps> = {
                    onChange: (opts?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "programId",
                            opts ? opts.map(_ => _.key) : undefined);
                    },
                    onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                        let res: Option[] =
                            programOptions.filter(_ => _.text.toLowerCase().indexOf(_filter.toLowerCase()) !== -1);
                        if (_selectedItems) {
                            res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return Promise.resolve(res);
                    },
                    selectedItems: selected
                };

                return InputsService.buildOptionsPicker(itemsProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                const values: string[] = [];
                const array: string[] = value;
                array.forEach(id => {
                    const program = this._props.programs.find(_ => _.id === id);
                    if (program) {
                        values.push(program.attributes.Name);
                    }
                });
                return values;
            },
            validateItem: (item: ProjectInfo, filterValue: any): boolean => {
                if (!filterValue || (<string[]>filterValue).length === 0) {
                    return true;
                }

                const programIds = filterValue as string[];
                return item.attributes.Program.map(_ => _.id).filter(_ => programIds.indexOf(_) !== -1).length > 0;
            }
        }
    private projectId: IFilterHelper<ProjectFilterValue, ProjectInfo> =
        {
            buildFilterElement: (attr: FilterAttribute<ProjectFilterValue>,
                filter: IFilter<ProjectFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const projectOptions: Option[] = this._props.projects.map(_ => ({ key: _.id, text: _.attributes.Name }));
                const selected: Option[] = [];

                filter.value?.projectId?.forEach((id: string) => {
                    const opt = projectOptions.find(_ => _.key === id);
                    if (opt !== undefined) {
                        selected.push(opt);
                    }
                });

                const itemsProps: Partial<OptionsPickerProps> = {
                    onChange: (opts?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "projectId",
                            opts ? opts.map(_ => _.key) : undefined);
                    },
                    onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                        let res: Option[] =
                            projectOptions.filter(_ => _.text.toLowerCase().indexOf(_filter.toLowerCase()) !== -1);
                        if (_selectedItems) {
                            res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return Promise.resolve(res);
                    },
                    selectedItems: selected
                };

                return InputsService.buildOptionsPicker(itemsProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                const values: string[] = [];
                const array: string[] = value;
                array.forEach(id => {
                    const project = this._props.projects.find(_ => _.id === id);
                    if (project) {
                        values.push(project.attributes.Name);
                    }
                });
                return values;
            },
            validateItem: (item: ProjectInfo, filterValue: any): boolean => {
                if (!filterValue || (<string[]>filterValue).length === 0) {
                    return true;
                }
                const projectIds = filterValue as string[];
                return projectIds.indexOf(item.id) > -1;
            }
        }
    private processId: IFilterHelper<ProjectFilterValue, ProjectInfo> =
        {
            buildFilterElement: (attr: FilterAttribute<ProjectFilterValue>,
                filter: IFilter<ProjectFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const processOptions: Option[] = distinctByKey(this._props.projects.map(_ => ({ key: _.process.id, text: _.process.name })), 'key');
                const selected: Option[] = [];
            
                filter.value?.processId?.forEach((id: string) => {
                    const opt = processOptions.find(_ => _.key === id);
                    if (opt !== undefined) {
                        selected.push(opt);
                    }
                });

                const itemsProps: Partial<OptionsPickerProps> = {
                    onChange: (opts?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "processId",
                            opts ? opts.map(_ => _.key) : undefined);
                    },
                    onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                        let res: Option[] =
                        processOptions.filter(_ => _.text.toLowerCase().indexOf(_filter.toLowerCase()) !== -1);
                        if (_selectedItems) {
                            res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return Promise.resolve(res);
                    },
                    selectedItems: selected
                };

                return InputsService.buildOptionsPicker(itemsProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                const values: string[] = [];
                const array: string[] = value;
                array.forEach(id => {
                    const process = this._props.projects.map(_ => _.process).find(_ => _.id === id);
                    if (process) {
                        values.push(process.name);
                    }
                });
                return values;
            },
            validateItem: (item: ProjectInfo, filterValue: any): boolean => {
                if (!filterValue || (<string[]>filterValue).length === 0) {
                    return true;
                }
                const processIds = filterValue as string[];
                return processIds.indexOf(item.process.id) > -1;
            }
        }
    private linkedSystem: IFilterHelper<ProjectFilterValue, ProjectInfo> =
        {
            buildFilterElement: (
                attr: FilterAttribute<ProjectFilterValue>,
                filter: IFilter<ProjectFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const types: SourceType[] = [];
                this._props.projects.forEach(project => {
                    project.sourceInfos.forEach(sourceInfo => {
                        if (types.indexOf(sourceInfo.type) === -1) {
                            types.push(sourceInfo.type);
                        }
                    });
                });

                const typeOptions: Option[] = types.map(_ => ({ key: _, text: SourceTypeMap[_] }));
                const selectedItems: Option[] = [];

                filter.value &&
                    filter.value.linkedSystem &&
                    filter.value.linkedSystem.forEach((id: string) => {
                        const opt = typeOptions.find(_ => _.key === id);
                        if (opt !== undefined) {
                            selectedItems.push(opt);
                        }
                    });

                const itemsProps: Partial<OptionsPickerProps> = {
                    onChange: (value?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "linkedSystem",
                            value && value.map(v => v.key));
                    },
                    onResolveSuggestions: (filter: string, selectedItems?: Option[]) => {
                        let res: Option[] =
                            typeOptions.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                        if (selectedItems) {
                            res = res.filter((opt: Option) => selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return Promise.resolve(res);
                    },
                    selectedItems: selectedItems
                }

                return InputsService.buildOptionsPicker(itemsProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                const values: string[] = [];
                (value as SourceType[]).forEach(system => {
                    values.push(SourceTypeMap[system]);
                });
                return values;
            },
            validateItem: (item: ProjectInfo, filterValue: any): boolean => {
                if (!filterValue || (<string[]>filterValue).length === 0) {
                    return true;
                }
                return item.sourceInfos.map(_ => _.type).filter(_ => (filterValue as SourceType[]).indexOf(_) !== -1).length > 0;
            }
        }
    private projectStageId: IFilterHelper<ProjectFilterValue, ProjectInfo> = {
        buildFilterElement: (
            attr: FilterAttribute<ProjectFilterValue>,
            filter: IFilter<ProjectFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const projectStageOptions: Option[] = distinctByKey(this._props.projects.map(_ => ({ key: _.attributes.Stage.id, text: _.attributes.Stage.name })), 'key');
            const selected: Option[] = [];
        
            filter.value?.projectStageId?.forEach((id: string) => {
                const opt = projectStageOptions.find(_ => _.key === id);
                if (opt !== undefined) {
                    selected.push(opt);
                }
            });

            const itemsProps: Partial<OptionsPickerProps> = {
                onChange: (opts?: Option[]) => {
                    onFilterEditComplete(attr.type,
                        "projectStageId",
                        opts ? opts.map(_ => _.key) : undefined);
                },
                onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                    let res: Option[] =
                    projectStageOptions.filter(_ => _.text.toLowerCase().indexOf(_filter.toLowerCase()) !== -1);
                    if (_selectedItems) {
                        res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                    }
                    return Promise.resolve(res);
                },
                selectedItems: selected
            };

            return InputsService.buildOptionsPicker(itemsProps);
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            return [];
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            return value;
        },
        getAttributeValues: (value: any): string[] => {
            const values: string[] = [];
            const array: string[] = value;
            array.forEach(id => {
                const projectStage = this._props.projects.map(_ => _.attributes.Stage).find(_ => _.id === id);
                if (projectStage) {
                    values.push(projectStage.name);
                }
            });
            return values;
        },
        validateItem: (item: ProjectInfo, filterValue: any): boolean => {
            if (!filterValue || (<string[]>filterValue).length === 0) {
                return true;
            }
            const projectStageIds = filterValue as string[];
            return projectStageIds.indexOf(item.attributes.Stage.id) > -1;
        }
    }

    private projectVisibility: IFilterHelper<ProjectFilterValue, ProjectInfo> =
        {
            buildFilterElement: (
                attr: FilterAttribute<ProjectFilterValue>,
                filter: IFilter<ProjectFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const visibilityOptions: Option[] = Object.entries(projectVisibilityMap).map(_ => ({ key: _[0], text: _[1].label }));
                const selectedItems: Option[] = [];

                filter.value?.projectVisibility?.forEach((visibility: string) => {
                    const opt = visibilityOptions.find(_ => _.key === visibility);
                    if (opt !== undefined) {
                        selectedItems.push(opt);
                    }
                });

                const itemsProps: Partial<OptionsPickerProps> = {
                    onChange: (value?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "projectVisibility",
                            value && value.map(v => v.key));
                    },
                    onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                        let res: Option[] = visibilityOptions.filter(_ => _.text.toLowerCase().includes(_filter.toLowerCase()));
                        if (_selectedItems) {
                            res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return Promise.resolve(res);
                    },
                    selectedItems: selectedItems
                }

                return InputsService.buildOptionsPicker(itemsProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                const values: string[] = [];
                (value as string[]).forEach(visibility => {
                    values.push(projectVisibilityMap[visibility].label);
                });
                return values;
            },
            validateItem: (item: ProjectInfo, filterValue: any): boolean => {
                if (!filterValue || (<string[]>filterValue).length === 0) {
                    return true;
                }
                return (filterValue as string[]).map(_ => projectVisibilityMap[_].value).includes(!!item.isPrivate);
            }
        }
    private percentComplete: IFilterHelper<ProjectFilterValue, ProjectInfo> =
        {
            buildFilterElement: (
                attr: FilterAttribute<ProjectFilterValue>,
                filter: IFilter<ProjectFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const percentCompleteValue: { from: number, to: number } = filter.value.percentComplete || { from: 0, to: 0 };
                const propsFrom = {
                    key: "from-slider",
                    onEditComplete: (value: number) => {
                        let newValue = Object.assign({}, percentCompleteValue);
                        newValue.from = value;
                        if (newValue.from > newValue.to) {
                            newValue.to = newValue.from;
                        }
                        onFilterEditComplete(attr.type, "percentComplete", newValue);
                    },
                    value: percentCompleteValue.from,
                    min: 0,
                    max: 100,
                    step: 10,
                    label: "From"
                };

                const propsTo = {
                    key: "to-slider",
                    onEditComplete: (value: number) => {
                        let newValue = Object.assign({}, percentCompleteValue);
                        newValue.to = value;
                        if (newValue.to < newValue.from) {
                            newValue.from = newValue.to;
                        }
                        onFilterEditComplete(attr.type, "percentComplete", newValue);
                    },
                    value: percentCompleteValue.to,
                    min: 0,
                    max: 100,
                    step: 10,
                    label: "To"
                }

                return React.createElement("div",
                    undefined,
                    [InputsService.buildSlider(propsFrom), InputsService.buildSlider(propsTo)]);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return undefined;
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                return value
                    ? [value.from.toString(), value.to.toString()]
                    : [];
            },
            validateItem: (item: ProjectInfo, filterValue: any): boolean => {
                if (!filterValue) {
                    return true;
                }

                const progressData = ProjectUtils.getTasksMetrics(item.sourceInfos, item.calculation.taskProgresses);
                const percentCompleteAttr = progressData
                    ? progressData.pctComplete != null
                        ? progressData.pctComplete
                        : toPercent(progressData.total, progressData.completed)
                    : 0;
                const { from, to } = filterValue;

                return from <= percentCompleteAttr && to >= percentCompleteAttr;
            }
        }

    private layoutId = buildLayoutFilterHelper<ProjectFilterValue, ProjectInfo>(() => this._props.layouts)

    private readonly _props: FilterHelperProps
    constructor(props: FilterHelperProps) {
        this._props = props;
    }

    public newFilter = (name: string): IActiveFilter => new ActiveFilter(name).withAttributes(namesof<ProjectAttrs>(["OverallStatus", "Manager"]));
    public helpersMap: { [K in keyof ProjectFilterValue]: IFilterHelper<ProjectFilterValue, ProjectInfo> } =
        {
            "attributes": this.attributes,
            "portfolioId": this.portfolioId,
            "programId": this.programId,
            "projectId": this.projectId,
            "processId": this.processId,
            "linkedSystem": this.linkedSystem,
            "percentComplete": this.percentComplete,
            "layoutId": this.layoutId,
            "projectVisibility": this.projectVisibility,
            "projectStageId": this.projectStageId
        }
}

export function buildLayoutFilterHelper<TFilterValue extends BaseFilterValue, TItem extends IWithLayout>(layouts: () => Layout[]): IFilterHelper<TFilterValue, TItem> {
    return {
        buildFilterElement: (attr: FilterAttribute<TFilterValue>,
            filter: IFilter<TFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const layoutOptions: Option[] = layouts().filter(_ => !_.isView).map(_ => ({ key: _.id, text: _.name }));
            const selected: Option[] = [];

            filter.value?.layoutId?.forEach((id: string) => {
                const opt = layoutOptions.find(_ => _.key === id);
                if (opt !== undefined) {
                    selected.push(opt);
                }
            });

            const itemsProps: Partial<OptionsPickerProps> = {
                onChange: (opts?: Option[]) => {
                    onFilterEditComplete(attr.type,
                        "layoutId",
                        opts ? opts.map(_ => _.key) : undefined);
                },
                onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                    let res: Option[] =
                        layoutOptions.filter(_ => _.text.toLowerCase().indexOf(_filter.toLowerCase()) !== -1);
                    if (_selectedItems) {
                        res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                    }
                    return Promise.resolve(res);
                },
                selectedItems: selected
            };

            return InputsService.buildOptionsPicker(itemsProps);
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            return [];
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            return value;
        },
        getAttributeValues: (value: any): string[] => {
            const values: string[] = [];
            const array: string[] = value;
            array.forEach(id => {
                const layout = layouts().find(_ => _.id === id);
                if (layout) {
                    values.push(layout.name);
                }
            });
            return values;
        },
        validateItem: (item: TItem, filterValue: any): boolean => {
            if (!filterValue || (<string[]>filterValue).length === 0) {
                return true;
            }

            const layoutIds = filterValue as string[];
            return !!item.layoutId && layoutIds.includes(item.layoutId);
        }
    }
}